@media (min-width: 47.5em) {
  .border_right {
    border-right: 0.0625rem solid #000000;
  }
}
@media (min-width: 47.5em) {
  .border_top {
    border-top: 0.0625rem solid #000000;
  }
}
@media (min-width: 61.875em) {
  .sidebar_left .page > .container > .row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
            flex-direction: row-reverse;
  }
  .sidebar_left .page > .container > .row main {
    padding-left: 4.375rem;
  }
}
@media (min-width: 75em) {
  .sidebar_left .page > .container > .row main {
    padding-left: 8.125rem;
  }
}
.arrow_link {
  -webkit-box-align: start;
          align-items: flex-start;
  color: #000000;
  display: -webkit-box;
  display: flex;
  text-decoration: none;
}
.arrow_link::before {
  background-image: url("../images/pfeil.svg");
  background-repeat: no-repeat;
  content: "";
  display: -webkit-box;
  display: flex;
  height: 0.625rem;
  margin-right: 1.9em;
  margin-top: 0.625rem;
  -webkit-transform-origin: left center;
          transform-origin: left center;
  -webkit-transition: -webkit-transform 300ms;
  transition: -webkit-transform 300ms;
  transition: transform 300ms;
  transition: transform 300ms, -webkit-transform 300ms;
  width: 6.25rem;
}
.arrow_link:hover,
.arrow_link:focus {
  text-decoration: underline;
}
.arrow_link:hover::before,
.arrow_link:focus::before {
  -webkit-transform: scaleX(1.18);
          transform: scaleX(1.18);
}
.nobreak {
  white-space: nowrap;
}
.nohyphens {
  -webkit-hyphens: none;
      -ms-hyphens: none;
          hyphens: none;
}
.hideme {
  display: none !important;
}
::-moz-selection {
  background: #d51317;
  color: #fff;
}
::selection {
  background: #d51317;
  color: #fff;
}
* {
  -webkit-tap-highlight-color: rgba(213, 19, 23, 0.1);
  box-sizing: border-box;
}
.page {
  background: #fff;
}
html {
  -webkit-text-size-adjust: 100%;
          text-size-adjust: 100%;
}
body {
  letter-spacing: calc((0.875rem + 0.125
                                 * (100vw - 20rem)
                                 / (93.75 - 20))
                                 / 1000 * 0);
  font-size: 0.875rem;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background: #fff;
  color: #000000;
  font-family: "Lato", sans-serif;
  -webkit-font-feature-settings: "liga", "kern";
          font-feature-settings: "liga", "kern";
  -webkit-font-kerning: normal;
          font-kerning: normal;
  font-smoothing: antialiased;
  font-weight: 300;
  hyphenate-limit-chars: 6 3 2;
  hyphenate-limit-lines: 2;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
  line-height: 1.9;
  margin: 0;
  text-rendering: geometricPrecision;
}
@media (min-width: 20rem) {
  body {
    font-size: calc(0.875rem + 0.125
                            * (100vw - 20rem)
                            / (93.75 - 20));
  }
}
@media (min-width: 93.75em) {
  body {
    font-size: 1rem;
  }
}
body.no_scroll {
  overflow: hidden;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  -webkit-hyphens: none;
      -ms-hyphens: none;
          hyphens: none;
  word-wrap: break-word;
}
h1 {
  letter-spacing: calc((1.3125rem + 0.4375
                                 * (100vw - 20rem)
                                 / (93.75 - 20))
                                 / 1000 * 0);
  font-size: 1.3125rem;
  color: #000000;
  font-family: "Lato", sans-serif;
  font-weight: 900;
  line-height: 1.35;
  margin: 0 0 0.95em;
  text-transform: uppercase;
}
@media (min-width: 20rem) {
  h1 {
    font-size: calc(1.3125rem + 0.4375
                            * (100vw - 20rem)
                            / (93.75 - 20));
  }
}
@media (min-width: 93.75em) {
  h1 {
    font-size: 1.75rem;
  }
}
h2 {
  letter-spacing: calc((0.875rem + 0.125
                                 * (100vw - 20rem)
                                 / (93.75 - 20))
                                 / 1000 * 0);
  font-size: 0.875rem;
  color: #000000;
  font-family: "Lato", sans-serif;
  font-weight: 900;
  line-height: 1.35;
  margin: 0 0 0.95em;
  padding-top: 1.9em;
  text-transform: uppercase;
}
@media (min-width: 20rem) {
  h2 {
    font-size: calc(0.875rem + 0.125
                            * (100vw - 20rem)
                            / (93.75 - 20));
  }
}
@media (min-width: 93.75em) {
  h2 {
    font-size: 1rem;
  }
}
h3 {
  letter-spacing: calc((0.875rem + 0.125
                                 * (100vw - 20rem)
                                 / (93.75 - 20))
                                 / 1000 * 0);
  font-size: 0.875rem;
  color: #000000;
  font-family: "Lato", sans-serif;
  font-weight: 900;
  line-height: 1.35;
  margin: 0 0 0.95em;
  padding-top: 0.95em;
}
@media (min-width: 20rem) {
  h3 {
    font-size: calc(0.875rem + 0.125
                            * (100vw - 20rem)
                            / (93.75 - 20));
  }
}
@media (min-width: 93.75em) {
  h3 {
    font-size: 1rem;
  }
}
h4 {
  letter-spacing: calc((0.75rem + 0.125
                                 * (100vw - 20rem)
                                 / (93.75 - 20))
                                 / 1000 * 80);
  color: #000000;
  font-family: "Lato", sans-serif;
  font-size: 0.75rem;
  font-weight: 300;
  line-height: 1.35;
  margin: 0 0 0.95em;
}
@media (min-width: 20rem) {
  h4 {
    font-size: calc(0.75rem + 0.125
                            * (100vw - 20rem)
                            / (93.75 - 20));
  }
}
@media (min-width: 93.75em) {
  h4 {
    font-size: 0.875rem;
  }
}
h5 {
  letter-spacing: calc((0.875rem + 0.125
                                 * (100vw - 20rem)
                                 / (93.75 - 20))
                                 / 1000 * 0);
  font-size: 0.875rem;
  font-family: "Lato", sans-serif;
  font-weight: 900;
  line-height: 1.35;
  margin: 0;
}
@media (min-width: 20rem) {
  h5 {
    font-size: calc(0.875rem + 0.125
                            * (100vw - 20rem)
                            / (93.75 - 20));
  }
}
@media (min-width: 93.75em) {
  h5 {
    font-size: 1rem;
  }
}
h6 {
  height: 0;
  margin: 0;
  overflow: hidden;
  width: 0;
}
p {
  -webkit-hyphens: none;
      -ms-hyphens: none;
          hyphens: none;
  margin: 0 0 0.95em;
  text-align: justify;
}
a {
  color: #d51317;
  -webkit-transition: color 300ms;
  transition: color 300ms;
}
a:active,
a.active {
  color: #000000;
  outline: 0;
}
a:focus {
  outline: 0.1875rem solid rgba(213, 19, 23, 0.8);
  outline-offset: 0.0625rem;
  text-decoration: none;
}
a:focus[data-focus-method="mouse"],
a:focus[data-focus-method="touch"] {
  outline: 0;
}
a:hover {
  text-decoration: none;
}
a[tabindex="-1"]:focus {
  outline: 0;
}
a[tabindex="-1"][id] {
  position: relative;
  top: -2.5rem;
}
a[tabindex="-1"][id]:after {
  content: "";
}
a.touch_link {
  color: #000000;
  display: block;
  text-decoration: none;
}
a.touch_link .title {
  color: #d51317;
  display: inline-block;
  margin-bottom: 0.475em;
  outline-offset: 0;
  padding: 0;
  text-decoration: underline;
  -webkit-transition: color 300ms;
  transition: color 300ms;
}
a.touch_link .title.active {
  color: #000000;
}
a.touch_link:focus {
  outline: 0;
}
a.touch_link:focus .title {
  outline: 0.1875rem solid rgba(213, 19, 23, 0.8);
  outline-offset: 0.0625rem;
  text-decoration: none;
}
a.touch_link:focus[data-focus-method="mouse"] .title,
a.touch_link:focus[data-focus-method="touch"] .title {
  outline: 0;
}
a.touch_link:hover .title {
  text-decoration: none;
}
.button {
  border-radius: 0;
  font-size: 1rem;
  font-weight: 900;
  padding: 0;
  position: relative;
  text-transform: uppercase;
  background: transparent;
  border: 0.0625rem solid transparent;
  color: #000000;
  display: inline-block;
  text-decoration: none;
  -webkit-transition: background 300ms, border 300ms, color 300ms;
  transition: background 300ms, border 300ms, color 300ms;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.button::after {
  background-color: #d51317;
  bottom: 0;
  content: "";
  display: block;
  height: 0.1875rem;
  left: 0;
  position: absolute;
  right: 0;
  -webkit-transition: bottom 300ms;
  transition: bottom 300ms;
}
.button.button_red {
  background-color: #d51317;
  -webkit-transition: 300ms;
  transition: 300ms;
}
.button.button_red .text {
  color: #fff;
  padding: 0 0.95em;
  -webkit-transition: 300ms;
  transition: 300ms;
}
.button.button_red .icon {
  fill: #fff;
  margin-left: 0.95em;
}
.button.button_red::after {
  display: none;
}
.button.button_red:hover,
.button.button_red:focus,
.button.button_red:active,
.button.button_red.active {
  background-color: #efeae4;
  text-decoration: none !important;
}
.button.button_red:hover .text,
.button.button_red:focus .text,
.button.button_red:active .text,
.button.button_red.active .text {
  color: #000000;
  text-decoration: none !important;
}
.button.button_red:hover .icon,
.button.button_red:focus .icon,
.button.button_red:active .icon,
.button.button_red.active .icon {
  fill: #000000;
}
.button > span {
  -webkit-box-align: center;
          align-items: center;
  display: -webkit-box;
  display: flex;
  height: calc(2.75rem - (0.0625rem * 2));
  -webkit-box-pack: center;
          justify-content: center;
}
.button .icon {
  fill: #000000;
  height: 1.5rem;
  -webkit-transition: fill 300ms;
  transition: fill 300ms;
  width: 1.5rem;
}
.button .text {
  font-weight: 900;
  padding: 0.63333333em 0;
  white-space: nowrap;
}
.button .icon + .text {
  padding-left: 0.63333333em;
}
.button:focus {
  box-shadow: 0 0 0 0.1875rem rgba(213, 19, 23, 0.2);
  outline: 0;
  background: transparent;
  border-color: transparent;
  color: #000000;
}
.button:focus .icon {
  fill: #000000;
}
.button:hover {
  background: transparent;
  border-color: transparent;
  color: #000000;
}
.button:hover .icon {
  fill: #000000;
}
.button:hover::after {
  bottom: 0.4375rem;
}
.button:active,
.button.active {
  box-shadow: 0 0 0.1875rem transparent inset;
  background: transparent;
  border: 0.0625rem solid transparent;
  color: #000000;
  display: inline-block;
  text-decoration: none;
  -webkit-transition: background 300ms, border 300ms, color 300ms;
  transition: background 300ms, border 300ms, color 300ms;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.button:active > span,
.button.active > span {
  -webkit-box-align: center;
          align-items: center;
  display: -webkit-box;
  display: flex;
  height: calc(2.75rem - (0.0625rem * 2));
  -webkit-box-pack: center;
          justify-content: center;
}
.button:active .icon,
.button.active .icon {
  fill: #000000;
  height: 1.5rem;
  -webkit-transition: fill 300ms;
  transition: fill 300ms;
  width: 1.5rem;
}
.button:active .text,
.button.active .text {
  font-weight: 900;
  padding: 0.63333333em 0;
  white-space: nowrap;
}
.button:active .icon + .text,
.button.active .icon + .text {
  padding-left: 0.63333333em;
}
.highlighted {
  -webkit-animation: highlighted_anchor 900ms;
          animation: highlighted_anchor 900ms;
}
strong {
  font-weight: 900;
}
em {
  font-style: normal;
}
blockquote {
  margin: 0 0 0.95em 1ch;
  text-indent: -1ch;
}
blockquote:before {
  content: "\201E";
}
blockquote:after {
  content: "\201C";
}
small {
  font-size: 85%;
}
sup {
  font-size: 85%;
  line-height: 1;
  position: relative;
  top: -0.3125rem;
}
sub {
  bottom: -0.125rem;
  font-size: 85%;
  line-height: 1;
  position: relative;
}
.table {
  -webkit-overflow-scrolling: touch;
  margin-bottom: 1.9em;
  overflow-y: auto;
}
* + .table {
  margin-top: 1.9em;
}
table {
  border-bottom: 0.0625rem solid #dcd1c4;
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0;
  width: 100%;
}
th {
  font-weight: 900;
  text-align: left;
}
td,
th {
  border-top: 0.0625rem solid #dcd1c4;
  padding: 0.63333333em;
  -webkit-transition: background 300ms;
  transition: background 300ms;
  vertical-align: top;
}
li {
  -webkit-hyphens: none;
      -ms-hyphens: none;
          hyphens: none;
  text-align: justify;
}
ol {
  counter-reset: list;
  list-style: none;
  margin: 0 0 0.95em;
  padding: 0;
}
ol > li {
  padding-left: 1.9em;
  position: relative;
}
ol > li:before {
  color: #d51317;
  content: counter(list) ".";
  counter-increment: list;
  font-weight: 900;
  left: 0;
  line-height: 1.9;
  position: absolute;
  text-align: right;
  width: 1.425em;
}
ol ol,
ol ul {
  margin: 0;
}
ul {
  list-style: url("../images/list_style_image_rectangle.svg");
  margin: 0 0 0.95em;
  padding-left: 1.425em;
}
ul > li {
  padding-left: 0.475em;
}
ul ol,
ul ul {
  margin: 0;
}
.defined_list dl {
  border-bottom: 0.0625rem solid #dcd1c4;
  margin: 0.95em 0;
}
@media (min-width: 35em) {
  .defined_list dl {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
}
.defined_list dt {
  border-top: 0.0625rem solid #dcd1c4;
  font-weight: 900;
  padding: 0.95em 0;
}
@media (min-width: 35em) {
  .defined_list dt {
    -webkit-box-flex: 0;
            flex: 0 0 40%;
    max-width: 40%;
    padding: 0.95em 0.95em 0.95em 0;
  }
}
.defined_list dd {
  margin: 0;
}
@media (min-width: 35em) {
  .defined_list dd {
    border-top: 0.0625rem solid #dcd1c4;
    -webkit-box-flex: 0;
            flex: 0 0 60%;
    max-width: 60%;
    padding: 0.95em 0.63333333em 0 0;
  }
}
nav {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
nav ol {
  margin: 0;
}
nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
nav li {
  margin: 0;
  padding: 0;
}
nav li:before {
  display: none;
}
figure {
  margin: 0 0 1.9em;
}
figure img {
  display: block;
}
figure.center {
  margin-left: auto;
  margin-right: auto;
}
figure.floatleft {
  float: left;
  margin-right: 1.9em;
}
figure.floatright {
  float: right;
  margin-left: 1.9em;
}
figcaption {
  font-size: 85%;
  margin-top: 0.475em;
  text-align: left;
}
.img_container {
  overflow: hidden;
}
.img_container img,
.img_container svg {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.img_resize {
  height: 0;
  position: relative;
  width: 100%;
}
img {
  border: 0;
  display: inline-block;
  height: auto;
  max-width: 100%;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
svg {
  height: 100%;
  -webkit-transform: scale(1);
          transform: scale(1);
  width: 100%;
}
svg a:focus {
  outline: 0;
}
input,
select,
textarea,
button {
  font-family: inherit;
  font-size: 1rem;
  font-weight: 300;
}
label {
  cursor: pointer;
}
input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
input[type="time"]::-webkit-inner-spin-button,
input[type="time"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
input:-webkit-autofill {
  -webkit-text-fill-color: #000000;
  box-shadow: 0 0 0 62.5rem #fff inset, 0 0 0.1875rem transparent inset, 0 0 0 0.1875rem rgba(0, 0, 0, 0.2) !important;
}
input::-ms-clear {
  display: none;
  height: 0;
  width: 0;
}
.form_disclaimer {
  background: #efeae4;
  border-radius: 0;
  color: #000000;
  font-size: 85%;
  margin-bottom: 0.95em;
  padding: 1.26666667em 1.9em;
}
::-webkit-input-placeholder {
  color: #404040;
  -webkit-user-select: none;
          user-select: none;
}
:-ms-input-placeholder {
  color: #404040;
  -ms-user-select: none;
      user-select: none;
}
::placeholder {
  color: #404040;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.required_asterisk {
  color: #d51317;
}
.required_description {
  font-size: 85%;
}
.error_warning {
  background: #d51317;
  color: #fff;
  display: none;
  font-weight: 900;
  margin-right: 0.475em;
  padding: 0 0.475em;
  text-align: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.error_warning.show {
  display: inline-block;
}
fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}
.legend {
  letter-spacing: calc((1.1875rem + 0.25
                                 * (100vw - 20rem)
                                 / (93.75 - 20))
                                 / 1000 * 0);
  font-size: 1.1875rem;
  color: #000000;
  font-family: "Lato", sans-serif;
  font-weight: 300;
  line-height: 1.35;
  margin: 0 0 0.95em;
  padding: 0.95em 0 0;
}
@media (min-width: 20rem) {
  .legend {
    font-size: calc(1.1875rem + 0.25
                            * (100vw - 20rem)
                            / (93.75 - 20));
  }
}
@media (min-width: 93.75em) {
  .legend {
    font-size: 1.4375rem;
  }
}
.label {
  display: inline-block;
  margin-bottom: 0.475em;
}
.input,
.textarea {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: #faf9f7;
  border: 0.0625rem solid #fff;
  border-bottom: 0.1875rem solid #d51317;
  border-radius: 0;
  box-shadow: 0 0 0.1875rem #fff inset;
  color: #000000;
  margin: 0 0 1.26666667em;
  outline: 0;
  width: 100%;
}
.input[aria-invalid="true"],
.textarea[aria-invalid="true"] {
  border-color: #d51317;
}
.input[aria-invalid="true"]:focus,
.textarea[aria-invalid="true"]:focus {
  border-color: transparent;
  box-shadow: 0 0 0.1875rem transparent inset, 0 0 0 0.1875rem rgba(0, 0, 0, 0.2);
  color: #000000;
}
.input:focus,
.textarea:focus {
  border-color: transparent;
  box-shadow: 0 0 0.1875rem transparent inset, 0 0 0 0.1875rem rgba(0, 0, 0, 0.2);
  color: #000000;
}
.input {
  height: 2.75rem;
  padding: 0 0.95em;
  -webkit-transition: border 300ms;
  transition: border 300ms;
}
.textarea {
  line-height: 1.9;
  overflow: hidden;
  padding: 0.95em;
  resize: none;
  -webkit-transition: border 300ms, height 300ms;
  transition: border 300ms, height 300ms;
}
.file {
  display: -webkit-box;
  display: flex;
  flex-basis: 65%;
  height: 2.75rem;
  margin: 0 0 1.26666667em;
  position: relative;
}
.file.error .file_name {
  border-color: #d51317;
}
.file .file_name {
  background: #fff;
  border: 0.0625rem solid #dcd1c4;
  border-radius: 0 0 0 0;
  box-shadow: 0 0 0.1875rem #dcd1c4 inset;
  color: #000000;
  -webkit-box-flex: 0;
          flex: 0 1 100%;
  height: 2.75rem;
  line-height: calc(2.75rem - (0.0625rem * 2));
  overflow: hidden;
  padding: 0 0.95em;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 8.125rem;
}
.file .file_button {
  border-radius: 0 0 0 0;
  -webkit-box-flex: 1;
          flex: 1 0 auto;
  padding: 0 1.9em;
  z-index: 10;
  background: transparent;
  border: 0.0625rem solid transparent;
  color: #000000;
  display: inline-block;
  text-decoration: none;
  -webkit-transition: background 300ms, border 300ms, color 300ms;
  transition: background 300ms, border 300ms, color 300ms;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.file .file_button > span {
  -webkit-box-align: center;
          align-items: center;
  display: -webkit-box;
  display: flex;
  height: calc(2.75rem - (0.0625rem * 2));
  -webkit-box-pack: center;
          justify-content: center;
}
.file .file_button .icon {
  fill: #000000;
  height: 1.5rem;
  -webkit-transition: fill 300ms;
  transition: fill 300ms;
  width: 1.5rem;
}
.file .file_button .text {
  font-weight: 900;
  padding: 0.63333333em 0;
  white-space: nowrap;
}
.file .file_button .icon + .text {
  padding-left: 0.63333333em;
}
.file .file_button.focus {
  box-shadow: 0 0 0 0.1875rem rgba(213, 19, 23, 0.2);
  background: transparent;
  border-color: transparent;
  color: #000000;
}
.file .file_button.focus .icon {
  fill: #000000;
}
.file [type="file"] {
  bottom: 0;
  color: transparent;
  cursor: pointer;
  display: block;
  height: 2.75rem;
  left: 0;
  margin: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 20;
}
.file [type="file"]:hover ~ .file_button {
  background: transparent;
  border-color: transparent;
  color: #000000;
}
.file [type="file"]:hover ~ .file_button .icon {
  fill: #000000;
}
.file [type="file"]:active ~ .file_button {
  box-shadow: 0 0 0.1875rem transparent inset;
  background: transparent;
  border: 0.0625rem solid transparent;
  color: #000000;
  display: inline-block;
  text-decoration: none;
  -webkit-transition: background 300ms, border 300ms, color 300ms;
  transition: background 300ms, border 300ms, color 300ms;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.file [type="file"]:active ~ .file_button > span {
  -webkit-box-align: center;
          align-items: center;
  display: -webkit-box;
  display: flex;
  height: calc(2.75rem - (0.0625rem * 2));
  -webkit-box-pack: center;
          justify-content: center;
}
.file [type="file"]:active ~ .file_button .icon {
  fill: #000000;
  height: 1.5rem;
  -webkit-transition: fill 300ms;
  transition: fill 300ms;
  width: 1.5rem;
}
.file [type="file"]:active ~ .file_button .text {
  font-weight: 900;
  padding: 0.63333333em 0;
  white-space: nowrap;
}
.file [type="file"]:active ~ .file_button .icon + .text {
  padding-left: 0.63333333em;
}
.control {
  -webkit-box-align: start;
          align-items: flex-start;
  display: -webkit-box;
  display: flex;
  margin: 0.475em 0.95em 0.95em 0;
}
.control.checkbox input:checked ~ .indicator .icon {
  opacity: 1;
  visibility: visible;
}
.control input {
  border: 0;
  cursor: pointer;
  height: 1.5rem;
  margin: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 1.5rem;
  z-index: 10;
}
.control input[aria-invalid="true"] ~ .indicator {
  border-color: #d51317;
}
.control input[aria-invalid="true"]:focus ~ .indicator {
  border-color: transparent;
  box-shadow: 0 0 0.1875rem transparent inset, 0 0 0 0.1875rem rgba(0, 0, 0, 0.2);
  color: #000000;
}
.control input:focus ~ .indicator {
  border-color: transparent;
  box-shadow: 0 0 0.1875rem transparent inset, 0 0 0 0.1875rem rgba(0, 0, 0, 0.2);
  color: #000000;
}
.control .indicator_wrapper {
  -webkit-box-align: center;
          align-items: center;
  display: -webkit-box;
  display: flex;
  flex-shrink: 0;
  margin-right: 0.95em;
  position: relative;
}
.control .indicator {
  background: #fff;
  border: 0.0625rem solid #dcd1c4;
  border-radius: 0;
  height: 1.375rem;
  position: relative;
  -webkit-transition: border 300ms;
  transition: border 300ms;
  width: 1.375rem;
}
.control .indicator .icon {
  fill: #000000;
  height: 1.25rem;
  opacity: 0;
  position: absolute;
  -webkit-transition: opacity 300ms;
  transition: opacity 300ms;
  visibility: hidden;
  width: 1.25rem;
}
.select_wrapper {
  display: block;
}
.select {
  background: #fff;
  border: 0.0625rem solid #dcd1c4;
  border-radius: 0;
  box-shadow: 0 0 0.1875rem #dcd1c4 inset;
  color: #000000;
  cursor: pointer;
  display: block;
  margin: 0 0 1.26666667em;
  padding: 0 0.95em;
  position: relative;
  -webkit-transition: border 300ms;
  transition: border 300ms;
  width: 100%;
}
.select:before {
  border: 0.625rem solid transparent;
  border-top-color: #000000;
  content: "";
  display: inline-block;
  height: 0;
  margin-top: -0.3125rem;
  pointer-events: none;
  position: absolute;
  right: 0.95em;
  top: 50%;
  width: 0;
}
.select.error {
  border-color: #d51317;
}
.select.error.focus {
  border-color: transparent;
  box-shadow: 0 0 0.1875rem transparent inset, 0 0 0 0.1875rem rgba(0, 0, 0, 0.2);
  color: #000000;
}
.select.focus {
  border-color: transparent;
  box-shadow: 0 0 0.1875rem transparent inset, 0 0 0 0.1875rem rgba(0, 0, 0, 0.2);
  color: #000000;
}
.select select {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: transparent;
  border: 0;
  color: #000000;
  cursor: pointer;
  height: calc(2.75rem - (0.0625rem * 2));
  margin: 0;
  outline: 0;
  overflow: hidden;
  padding: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.select select::-ms-expand {
  display: none;
}
.select select:focus:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000000;
}
.select select:focus::-ms-value {
  background: none;
  color: #000000;
}
@media (min-width: 35em) {
  .formular .button_wrapper {
    text-align: right;
  }
}
button {
  cursor: pointer;
  height: 2.75rem;
  margin: 0.95em 0;
  outline: 0;
  overflow: hidden;
  padding: 0 1.9em;
  width: 100%;
}
@media (min-width: 35em) {
  button {
    width: auto;
  }
}
button::-moz-focus-inner {
  border: 0;
}
@-webkit-keyframes preloader_rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
  }
}
@keyframes preloader_rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
  }
}
@-webkit-keyframes highlighted_anchor {
  0% {
    background: transparent;
    box-shadow: none;
  }
  70% {
    background: rgba(213, 19, 23, 0.1);
    box-shadow: 0 0 0 0.625rem rgba(213, 19, 23, 0.1);
  }
  100% {
    background: transparent;
    box-shadow: none;
  }
}
@keyframes highlighted_anchor {
  0% {
    background: transparent;
    box-shadow: none;
  }
  70% {
    background: rgba(213, 19, 23, 0.1);
    box-shadow: 0 0 0 0.625rem rgba(213, 19, 23, 0.1);
  }
  100% {
    background: transparent;
    box-shadow: none;
  }
}
@-webkit-keyframes button_appear {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-3em);
            transform: translateX(-3em);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@keyframes button_appear {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-3em);
            transform: translateX(-3em);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@-webkit-keyframes image_gallery_spinner {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes image_gallery_spinner {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes image_gallery_bounce_from_right {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: -1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@keyframes image_gallery_bounce_from_right {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: -1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@-webkit-keyframes image_gallery_bounce_from_left {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: 1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@keyframes image_gallery_bounce_from_left {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: 1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@media screen {
  .page {
    min-width: 20rem;
  }
  .container {
    margin: 0 auto;
    max-width: 100rem;
    min-width: 20rem;
    padding: 0 0.9375rem;
  }
  .row {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    flex-wrap: wrap;
    margin-left: -0.46875rem;
    margin-right: -0.46875rem;
  }
  .row.around_xsmall {
    justify-content: space-around;
  }
  .row.between_xsmall {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xsmall {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xsmall {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xsmall {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xsmall {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xsmall {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xsmall {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xsmall {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
  .row.no_column_margin {
    margin-left: 0;
    margin-right: 0;
  }
  .row.no_column_margin > [class*="span"] {
    padding-left: 0;
    padding-right: 0;
  }
  .row.half_column_margin {
    margin-left: -0.234375rem;
    margin-right: -0.234375rem;
  }
  .row.half_column_margin > [class*="span"] {
    padding-left: 0.234375rem;
    padding-right: 0.234375rem;
  }
  [class*="span"] {
    flex-basis: 100%;
    max-width: 100%;
    padding-left: 0.46875rem;
    padding-right: 0.46875rem;
  }
  [class*="span"].span_column {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
  }
  .span1_xsmall {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xsmall {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xsmall {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xsmall {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xsmall {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xsmall {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xsmall {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xsmall {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xsmall {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xsmall {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xsmall {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xsmall {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xsmall {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xsmall {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xsmall {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xsmall {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xsmall {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xsmall {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xsmall {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xsmall {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xsmall {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xsmall {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xsmall {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xsmall {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
  /* header {
    overflow: hidden;
}*/
  .navigation_wrapper {
    padding-top: 4.65rem;
  }
  .header_go_to_top {
    opacity: 0;
  }
  .header_go_to_top.show {
    opacity: 1;
  }
  .go_to_top {
    -webkit-box-align: center;
            align-items: center;
    color: #b69f84;
    display: none;
    font-size: 80%;
    font-weight: 900;
    height: 2.75rem;
    left: 0;
    position: absolute;
    text-decoration: none;
    text-transform: uppercase;
    top: 0;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    left: 12.5rem;
  }
  .go_to_top .icon {
    display: block;
    fill: #b69f84;
    height: 1.375rem;
    margin-right: 0.95em;
    padding: 0.125rem;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    -webkit-transition: all 300ms;
    transition: all 300ms;
    width: 1.375rem;
  }
  .go_to_top:hover {
    color: #d51317;
  }
  .go_to_top:hover .icon {
    fill: #d51317;
  }
  .icon_container::after {
    background-color: #fff;
    content: "";
    display: block;
    height: 0.1875rem;
    left: -500%;
    position: absolute;
    right: -500%;
  }
  .icon_wrapper {
    -webkit-box-align: center;
            align-items: center;
    background-color: #efeae4;
    display: -webkit-box;
    display: flex;
    height: 2.75rem;
    -webkit-box-pack: center;
            justify-content: center;
    margin-top: 0.9375rem;
    position: relative;
  }
  .icon_wrapper::before {
    background-color: #efeae4;
    bottom: 0;
    content: "";
    display: block;
    left: -500%;
    position: absolute;
    top: 0;
    width: 500%;
  }
  .icon_wrapper::after {
    background-color: #efeae4;
    bottom: 0;
    content: "";
    display: block;
    position: absolute;
    right: -500%;
    top: 0;
    width: 500%;
  }
  .icon_wrapper .toggle_search_box {
    width: auto;
  }
  .icon_wrapper .toggle_search_box .open_search_box a {
    display: -webkit-box;
    display: flex;
  }
  .icon_wrapper .toggle_search_box .open_search_box a:hover {
    color: #000000;
  }
  .icon_wrapper .toggle_search_box:hover {
    color: #000000;
  }
  .icon_wrapper .toggle_search_box:hover .icon {
    fill: #000000;
  }
  .icon_wrapper .go_to_contact a,
  .icon_wrapper .go_to_video a {
    display: block;
  }
  .icon_wrapper .go_to_contact a span,
  .icon_wrapper .go_to_video a span {
    display: -webkit-box;
    display: flex;
  }
  .icon_wrapper .go_to_contact .icon,
  .icon_wrapper .go_to_video .icon {
    display: block;
    padding: 0.25rem;
  }
  .icon_wrapper .toggle_search_box,
  .icon_wrapper .go_to_contact,
  .icon_wrapper .go_to_video {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    height: 2.75rem;
    padding: 0 0.475em;
  }
  .icon_wrapper .toggle_search_box .icon,
  .icon_wrapper .go_to_contact .icon,
  .icon_wrapper .go_to_video .icon {
    fill: #b69f84;
    height: 1.875rem;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    width: 1.875rem;
  }
  .icon_wrapper .toggle_search_box a,
  .icon_wrapper .go_to_contact a,
  .icon_wrapper .go_to_video a {
    color: #b69f84;
    font-size: 80%;
    font-weight: 900;
    text-decoration: none;
    text-transform: uppercase;
  }
  .icon_wrapper .toggle_search_box a .text,
  .icon_wrapper .go_to_contact a .text,
  .icon_wrapper .go_to_video a .text {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    display: none;
  }
  .icon_wrapper .toggle_search_box a:hover,
  .icon_wrapper .go_to_contact a:hover,
  .icon_wrapper .go_to_video a:hover {
    color: #000000;
  }
  .icon_wrapper .toggle_search_box a:hover .icon,
  .icon_wrapper .go_to_contact a:hover .icon,
  .icon_wrapper .go_to_video a:hover .icon {
    fill: #000000;
  }
  .author_hor {
    display: inline-block;
    fill: #efeae4;
    height: 0.5625rem;
    line-height: 0.5625rem;
    position: absolute;
    right: 0.3125rem;
    top: 0.1875rem;
    width: 4.6875rem;
  }
  .author_ver {
    display: none;
    fill: #efeae4;
    height: 4.6875rem;
    line-height: 4.6875rem;
    width: 0.5625rem;
  }
  .header_image img {
    display: block;
    margin: 0 auto;
  }
  .header_background {
    background-repeat: no-repeat;
    background-size: contain;
    width: auto;
  }
  .header_image_wrapper {
    margin-top: 1.875rem;
    position: relative;
  }
  .header_image_wrapper .go_to_contents {
    letter-spacing: calc((0.75rem + 0.125
                                 * (100vw - 20rem)
                                 / (93.75 - 20))
                                 / 1000 * 80);
    font-size: 0.75rem;
    color: #000000;
    display: none;
    position: absolute;
    right: 9vw;
    text-decoration: none;
    text-transform: uppercase;
    top: 0;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    -webkit-transform-origin: top right;
            transform-origin: top right;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    width: 40vw;
  }
  .header_image_wrapper .go_to_contents .icon {
    display: -webkit-box;
    display: flex;
    height: 0.625rem;
    margin-right: 1.9em;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-transition: -webkit-transform 300ms;
    transition: -webkit-transform 300ms;
    transition: transform 300ms;
    transition: transform 300ms, -webkit-transform 300ms;
    width: 6.25rem;
  }
  .header_image_wrapper .go_to_contents:hover {
    color: #d51317;
  }
  .header_image_wrapper .go_to_contents:hover .icon {
    -webkit-transform: rotate(180deg) translateX(0.625rem);
            transform: rotate(180deg) translateX(0.625rem);
  }
  .no_hyphens p {
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
    background-color: red;
  }
  .company_name .addition {
    display: block;
    font-weight: 300;
    text-transform: none;
  }
  .imprint main h2 {
    border-top: 0.0625rem solid #dcd1c4;
  }
  .imprint main .company_name {
    border-top: 0;
  }
  main {
    display: block;
  }
  main h1 {
    margin-bottom: 1.9em;
  }
  main h1::after {
    background-color: #d51317;
    content: "";
    display: block;
    height: 0.0625rem;
    margin-top: 0.95em;
    width: 6.25rem;
  }
  .startpage h1 {
    margin: 3.8em 0 1.9em 0;
  }
  .startpage h1::after {
    display: none;
  }
  .startpage main {
    padding-top: 0;
  }
  .infobanner_reachability_content h2 {
    background-color: #efeae4;
    font-size: 120%;
    padding: 1.235em 0;
  }
  .infobanner_reachability_content a {
    -webkit-animation: 1500ms button_appear ease-in-out;
            animation: 1500ms button_appear ease-in-out;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    opacity: 0;
  }
  .company_logo {
    display: block;
    max-width: 12.5rem;
  }
  .company_logo.company_logo_2 {
    display: none;
  }
  .breadcrumbs {
    -webkit-box-align: center;
            align-items: center;
    bottom: 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    flex-wrap: wrap;
    -webkit-box-pack: ccenter;
            justify-content: ccenter;
    margin-bottom: 3.8em;
    margin-top: 1.9em;
    position: relative;
  }
  .breadcrumbs p {
    font-size: 85%;
    font-weight: 900;
    margin-bottom: 0.95em;
    text-transform: uppercase;
  }
  .breadcrumbs ol {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    flex-wrap: nowrap;
    position: relative;
  }
  .breadcrumbs li {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
  }
  .breadcrumbs li::after {
    background-color: #d51317;
    content: "";
    display: inline-block;
    height: 0.0625rem;
    margin-left: 0.4375rem;
    margin-right: 0.4375rem;
    width: 0.9375rem;
  }
  .breadcrumbs li:last-of-type::after {
    display: none;
  }
  .breadcrumbs li a {
    color: #000000;
    font-size: 90%;
  }
  .contact_wrapper {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
  .contact_wrapper p {
    margin-right: 2.85em;
  }
  .contact_wrapper .address > span {
    display: block;
  }
  .contact {
    display: -webkit-box;
    display: flex;
  }
  .contact strong {
    min-width: 4.375rem;
  }
  .address_vcard_button {
    display: inline-block;
    margin: 0 0 3.8em 0;
  }
  .address_call_button {
    display: inline-block;
    margin: 0 1.9em 0.95em 0;
  }
  .opening_times .title {
    padding-top: 0.95em;
  }
  .opening_times .entries {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
  .opening_times .entry {
    margin-right: 2.85em;
  }
  .download_overview {
    margin-bottom: 0.95em;
  }
  .download_overview .touch_link {
    display: -webkit-box;
    display: flex;
  }
  .download_overview .touch_link .title_wrapper {
    margin-bottom: 0.475em;
    padding-top: 0;
  }
  .download_overview .icon {
    flex-shrink: 0;
    height: 1.5rem;
    margin-right: 0.95em;
    width: 1.5rem;
  }
  .download_overview .icon_doc {
    fill: #2a5699;
  }
  .download_overview .icon_xls {
    fill: #4e892d;
  }
  .download_overview .icon_pdf {
    fill: #e44e4e;
  }
  .download_overview .icon_online {
    fill: #0074ce;
  }
  .onlinetools_popup_overview .entry {
    margin: 0 0 0.95em;
  }
  .onlinetools_popup_overview .description {
    display: block;
    margin-bottom: 0.95em;
  }
  .onlinetools_popup_overview .touch_link .title {
    margin: 0 0 0.475em;
    padding: 0;
  }
  .legal_notice_tax_aut_aab {
    margin-bottom: 0.95em;
    padding-top: 0.95em;
  }
  .legal_notice_tax_aut_aab [href$=".pdf"] {
    display: inline-block;
  }
  .legal_notice_tax_aut_aab [href$=".pdf"] > span {
    display: -webkit-box;
    display: flex;
  }
  .legal_notice_tax_aut_aab .icon {
    display: block;
    fill: #e44e4e;
    height: 1.5rem;
    margin-right: 0.76em;
    min-width: 1.5rem;
    width: 1.5rem;
  }
  .legal_notice_tax_aut {
    margin-bottom: 0.95em;
    padding-top: 0.95em;
  }
  .carrier .job_overview {
    background: #efeae4;
  }
  .carrier .job_overview .entry {
    padding: 1.9em;
    text-align: center;
    -webkit-transition: 300ms;
    transition: 300ms;
  }
  .carrier .job_overview .entry:hover {
    background: #b69f84;
  }
  .carrier .job_overview .entry:hover h2 {
    color: #fff;
  }
  .carrier .benefit_wrapper {
    padding-bottom: 1.9em;
  }
  .carrier .content_slider .slide {
    text-decoration: none;
    color: #000000;
  }
  .carrier .content_slider .slide:hover .go_to_story {
    text-decoration: underline;
  }
  .carrier .content_slider .slide .go_to_story {
    color: #d51317;
  }
  .carrier .button_benefits {
    text-align: right;
  }
  .carrier .button_benefits a {
    text-decoration: none;
  }
  .bottom_spacing {
    margin-bottom: 1.9em;
  }
  .benefits_text_bg {
    background-color: #efeae4;
    padding: 0 1.9em;
  }
  .benefits_text_bg p,
  .benefits_text_bg h2 {
    margin-bottom: 0;
  }
  .startpage_figure figcaption {
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
  }
  aside {
    padding-top: 7.6em;
  }
  aside .entry {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
  }
  aside .entry.sidebar_teaser {
    background-color: #efeae4;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    padding: 1.9em;
  }
  aside .entry.sidebar_teaser h3 {
    padding-top: 0;
  }
  .sidebar > .folder_content > .entry {
    margin-bottom: 3.8em;
  }
  .footer_address {
    -webkit-box-align: start;
            align-items: flex-start;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
            flex-direction: column-reverse;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .footer_address .address > span {
    display: inline-block;
  }
  .footer_address .company_logo {
    display: block;
    min-width: 15.625rem;
  }
  .footer_address .company_logo.company_logo_2 {
    display: none;
  }
  .footer_address .contact_wrapper {
    display: -webkit-box;
    display: flex;
    margin-top: 1.9em;
  }
  .footer_address .contact_wrapper > p {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    margin: 0;
    text-transform: uppercase;
  }
  .footer_address .contact_wrapper > p > span {
    margin-right: 1.9em;
  }
  .footer_address .contact_wrapper > p > span a {
    color: #000000;
  }
  .footer_address .contact_wrapper > p > span > span {
    margin-right: 0.3125rem;
  }
  .footer_address .contact_wrapper > p > span.mail::after {
    display: none;
  }
  footer .icon_wrapper {
    -webkit-box-align: start;
            align-items: flex-start;
    background-color: #d51317;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    height: auto;
    margin-bottom: 0;
    margin-top: 3.8em;
  }
  footer .icon_wrapper::before,
  footer .icon_wrapper::after {
    background-color: #d51317;
  }
  footer .icon_wrapper .related_links {
    -webkit-box-flex: 1;
            flex: 1 0 auto;
  }
  footer .icon_wrapper .related_links ul {
    -webkit-box-align: start;
            align-items: flex-start;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    margin-bottom: 1.9em;
    margin-top: 1.9em;
  }
  footer .icon_wrapper .related_links ul li {
    margin-right: 1.9em;
  }
  footer .icon_wrapper .related_links ul a {
    color: #fff;
    font-size: 80%;
    font-weight: 900;
    text-decoration: none;
    text-transform: uppercase;
  }
  footer .icon_wrapper .related_links ul a .text {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
  }
  footer .icon_wrapper .related_links ul a:hover {
    color: #000000;
  }
  footer .icon_wrapper .related_links ul a:hover .icon {
    fill: #000000;
  }
  footer .icon_wrapper .go_to_top {
    color: #fff;
    display: -webkit-box;
    display: flex;
    line-height: 0.625rem;
    position: relative;
  }
  footer .icon_wrapper .go_to_top .icon {
    fill: #fff;
  }
  footer .icon_wrapper .go_to_top:hover {
    color: #000000;
  }
  footer .icon_wrapper .go_to_top:hover .icon {
    fill: #000000;
  }
  .visible_for_screen_reader {
    border: 0;
    clip: rect(0.0625rem, 0.0625rem, 0.0625rem, 0.0625rem);
    height: 0.0625rem;
    left: -624.9375rem;
    overflow: hidden;
    padding: 0;
    position: absolute;
    top: -624.9375rem;
    width: 0.0625rem;
  }
  .bypass_blocks {
    left: 0;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    z-index: 6000;
  }
  .bypass_blocks li {
    display: block;
    width: 20rem;
  }
  .bypass_blocks a {
    background: #fff;
    color: #d51317;
    display: inline-block;
    font-size: 85%;
    height: 0.0625rem;
    left: -624.9375rem;
    overflow: hidden;
    padding: 0.475em;
    position: absolute;
    text-decoration: underline;
    top: -624.9375rem;
    width: 0.0625rem;
  }
  .bypass_blocks a:focus {
    color: #d51317;
    height: auto;
    left: 0.475em;
    top: 0.475em;
    width: auto;
  }
  abbr {
    text-decoration: none;
  }
  [role="tablist"] {
    margin-bottom: 1.9em;
  }
  * + [role="tablist"] {
    margin-top: 1.9em;
  }
  [role="tab"] {
    -webkit-box-align: center;
            align-items: center;
    border-top: 0.0625rem solid #dcd1c4;
    cursor: pointer;
    display: -webkit-box;
    display: flex;
    font-size: 100%;
    margin: 0 0 0.475em;
    min-width: 100%;
    padding: 0.63333333em 0;
  }
  [role="tab"] .title {
    flex-basis: 100%;
    margin-right: 0.95em;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  [role="tab"] .icon {
    display: block;
    fill: #d51317;
    height: 1.5rem;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    width: 1.5rem;
  }
  [role="tab"][aria-expanded="true"] {
    border-color: #d51317;
  }
  [role="tab"][aria-expanded="true"] .icon {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }
  [role="tab"]:focus {
    border-color: #d51317;
    outline: 0;
  }
  [role="tab"]:focus .title {
    color: #d51317;
  }
  [role="tabpanel"] {
    height: auto;
    margin: 0;
    overflow: hidden;
  }
  [role="tabpanel"][aria-hidden="false"] {
    margin: 1.9em 0;
  }
  .csstransitions [role="tabpanel"].animated {
    -webkit-transition: margin 600ms, max-height 600ms;
    transition: margin 600ms, max-height 600ms;
  }
  .edit_in_cms {
    display: none;
  }
  .cookie_banner {
    -webkit-box-align: center;
            align-items: center;
    background: rgba(255, 255, 255, 0.95);
    border-top: 0.1875rem solid #d51317;
    bottom: 0;
    box-shadow: 0 -0.625rem 1.875rem #fff;
    display: -webkit-box;
    display: flex;
    font-size: 85%;
    left: 0;
    position: fixed;
    right: 0;
    z-index: 9000;
  }
  .cookie_banner p {
    flex-basis: 100%;
    margin: 0;
    padding: 1.26666667em 0.95em 1.26666667em 1.9em;
  }
  .cookie_banner .close {
    cursor: pointer;
    display: block;
    fill: #d51317;
    flex-shrink: 0;
    height: 2.75rem;
    padding: 0.95em;
    width: 2.75rem;
  }
  body.cb_visible .page {
    margin-bottom: 9.375rem;
  }
  .dialog_wrapper {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    height: 100%;
    left: 0;
    overflow: auto;
    padding: 1.9em 0;
    position: fixed;
    top: 0;
    visibility: hidden;
    width: 100%;
    z-index: 7100;
  }
  .dialog_wrapper.show {
    visibility: visible;
  }
  .dialog_overlay {
    background: rgba(0, 0, 0, 0.5);
    cursor: pointer;
    height: 100%;
    left: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    visibility: hidden;
    width: 100%;
    z-index: 0;
  }
  .dialog_overlay.show {
    opacity: 1;
    visibility: visible;
  }
  [role="dialog"] {
    background: #fff;
    border-radius: 0;
    box-shadow: 0 0.0625rem 0.375rem rgba(0, 0, 0, 0.3), 0 0.0625rem 0.25rem rgba(0, 0, 0, 0.2);
    margin: -0.625rem auto 0;
    max-height: 28.125rem;
    max-width: 35em;
    min-width: 18.1rem;
    opacity: 0;
    outline: 0;
    padding: 1.9em;
    position: relative;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    visibility: hidden;
    width: calc(100% - 1.9em);
    z-index: 10;
  }
  [role="dialog"].show {
    margin-top: 0;
    opacity: 1;
    visibility: visible;
  }
  [role="dialog"] .title {
    padding: 0 2.375em 0 0;
  }
  [role="dialog"] .description {
    padding: 0 0 0.95em 0;
  }
  [role="dialog"] .close {
    display: block;
    fill: #d51317;
    height: 2.75rem;
    margin-left: auto;
    padding: 0.31666667em;
    position: absolute;
    right: 0.95em;
    top: 1.26666667em;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 2.75rem;
  }
  [role="dialog"] .close:focus,
  [role="dialog"] .close:hover {
    fill: #000000;
  }
  .dropdown_menu {
    display: none;
  }
  .dropdown_menu > ul {
    display: -webkit-box;
    display: flex;
    position: relative;
  }
  .dropdown_menu > ul > li {
    -webkit-box-align: center;
            align-items: center;
  }
  .dropdown_menu > ul > li:last-child ul li:last-child {
    -webkit-box-flex: 100%;
            flex: 100%;
  }
  .dropdown_menu > ul > li:hover > a::after,
  .dropdown_menu > ul > li.hover > a::after {
    opacity: 1;
    -webkit-transform: translateY(-0.4375rem);
            transform: translateY(-0.4375rem);
  }
  .dropdown_menu > ul > li:hover ul,
  .dropdown_menu > ul > li.hover ul {
    visibility: visible;
  }
  .dropdown_menu > ul > li.active > a::after {
    opacity: 1;
  }
  .dropdown_menu > ul > li > a {
    -webkit-box-align: center;
            align-items: center;
    color: #000000;
    display: -webkit-box;
    display: flex;
    font-weight: 900;
    padding: 0.95em;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    white-space: nowrap;
  }
  .dropdown_menu > ul > li > a::after {
    background-color: #d51317;
    bottom: -0.1875rem;
    content: "";
    display: block;
    height: 0.1875rem;
    left: 0.95em;
    opacity: 0;
    position: absolute;
    right: 0.95em;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    z-index: 1100;
  }
  .dropdown_menu > ul > li ul {
    background: #fff;
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    left: -0.8125rem;
    padding: 1.4375rem 0 2.1875rem 0;
    position: absolute;
    right: 0;
    visibility: hidden;
    z-index: 1000;
  }
  .dropdown_menu > ul > li ul::after {
    background-color: #d51317;
    bottom: 1.9em;
    content: "";
    display: block;
    height: 0.0625rem;
    left: 1.9em;
    position: absolute;
    right: 0;
  }
  .dropdown_menu > ul > li ul:target {
    visibility: visible;
  }
  .dropdown_menu > ul > li ul li {
    -webkit-box-flex: 50%;
            flex: 50%;
  }
  .dropdown_menu > ul > li ul a {
    color: #000000;
    display: block;
    padding: 0.3125rem 1.9em;
    text-decoration: none;
    text-transform: uppercase;
    white-space: nowrap;
  }
  .dropdown_menu > ul > li ul a:hover {
    text-decoration: underline;
  }
  .dropdown_menu > ul > li ul a:focus {
    outline-offset: -0.125rem;
  }
  .dropdown_menu > ul > li ul a.active {
    color: #d51317;
    text-decoration: underline;
  }
  .google_maps {
    margin: 0 0 0.95em;
  }
  .google_maps .map {
    background: #efeae4;
    height: 50vh;
    min-height: 25rem;
  }
  .google_maps .map img {
    max-width: none;
  }
  .google_maps .map_print {
    display: none;
  }
  .google_maps .travel_mode {
    display: -webkit-box;
    display: flex;
    margin: 0 0 0.95em;
  }
  .google_maps .travel_mode input {
    opacity: 0;
    position: absolute;
  }
  .google_maps .travel_mode input:checked ~ .travel_mode_radio {
    z-index: 5;
    background: #dcd1c4;
    border-color: #d3c5b4;
    color: #000000;
  }
  .google_maps .travel_mode input:checked ~ .travel_mode_radio .icon {
    fill: #000000;
  }
  .google_maps .travel_mode input:focus ~ .travel_mode_radio {
    box-shadow: 0 0 0 0.1875rem rgba(213, 19, 23, 0.2);
    z-index: 15;
    background: #ec2f33;
    border-color: #be1114;
    color: #fff;
  }
  .google_maps .travel_mode input:focus ~ .travel_mode_radio .icon {
    fill: #fff;
  }
  .google_maps .travel_mode_entry {
    flex-basis: 100%;
    height: 2.75rem;
  }
  .google_maps .travel_mode_radio {
    cursor: pointer;
    position: relative;
    width: 100%;
    background: #fff;
    border: 0.0625rem solid #dcd1c4;
    color: #000000;
    display: inline-block;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .google_maps .travel_mode_radio > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    height: calc(2.75rem - (0.0625rem * 2));
    -webkit-box-pack: center;
            justify-content: center;
  }
  .google_maps .travel_mode_radio .icon {
    fill: #000000;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .google_maps .travel_mode_radio .text {
    font-weight: 900;
    padding: 0.63333333em 0;
    white-space: nowrap;
  }
  .google_maps .travel_mode_radio .icon + .text {
    padding-left: 0.63333333em;
  }
  .google_maps .travel_mode_radio .icon {
    fill: #000000;
    min-width: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
  }
  .google_maps .travel_mode_radio.driving {
    border-radius: 0 0 0 0;
    border-right-width: 0;
  }
  .google_maps .travel_mode_radio.transit,
  .google_maps .travel_mode_radio.bicycling {
    border-left-width: 0;
    border-right-width: 0;
  }
  .google_maps .travel_mode_radio.walking {
    border-left-width: 0;
    border-radius: 0 0 0 0;
  }
  .google_maps .warnings {
    background: #f6ecec;
    border-radius: 0;
    color: #d51317;
    font-size: 85%;
    margin-bottom: 0.95em;
    padding: 1.26666667em 1.9em;
  }
  .google_maps .direction {
    margin: 0.95em 0;
  }
  .google_maps .button_wrapper {
    margin-top: auto;
  }
  .google_maps .panel_wrapper {
    padding-top: 0.95em;
  }
  .google_maps .steps {
    min-width: 100%;
  }
  .google_maps .step {
    border-top: 0.0625rem solid #dcd1c4;
    display: -webkit-box;
    display: flex;
  }
  .google_maps .step.last {
    border-bottom: 0.0625rem solid #dcd1c4;
  }
  .google_maps .step:before {
    font-size: 85%;
    padding: 0.95em 0;
  }
  .google_maps .instructions,
  .google_maps .distance {
    font-size: 85%;
    padding: 0.95em 0;
  }
  .google_maps .instructions {
    overflow: hidden;
    padding-left: 0.95em;
    width: 100%;
    word-wrap: break-word;
  }
  .google_maps .distance {
    min-width: 5rem;
    text-align: right;
  }
  .image_gallery_overview {
    margin-bottom: 1.9em;
    position: relative;
  }
  .image_gallery_entry {
    background-color: #efeae4;
    border-bottom: 0.1875rem solid #fff;
    border-right: 0.1875rem solid #fff;
    display: block;
    position: relative;
  }
  .image_gallery_entry:focus,
  .image_gallery_entry:hover {
    outline: 0;
  }
  .image_gallery_entry:focus .open,
  .image_gallery_entry:hover .open {
    opacity: 1;
    visibility: visible;
  }
  .image_gallery_entry:focus .open svg,
  .image_gallery_entry:hover .open svg {
    -webkit-animation: news_photo 900ms;
            animation: news_photo 900ms;
  }
  .image_gallery_entry .open {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    display: -webkit-box;
    display: flex;
    fill: #fff;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: all 600ms;
    transition: all 600ms;
    visibility: hidden;
  }
  .image_gallery_entry .open svg {
    height: 3.75rem;
    margin: auto;
    width: 3.75rem;
  }
  .image_gallery_entry .image {
    background-position: 50%;
    background-size: cover;
    height: 0;
    overflow: hidden;
    padding-bottom: 62.5%;
  }
  .image_gallery_overlay {
    background: #000000;
    bottom: 0;
    height: 100%;
    left: 0;
    opacity: 0;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    visibility: hidden;
    width: 100%;
    z-index: 9999;
  }
  .image_gallery_overlay.show {
    opacity: 1;
    visibility: visible;
  }
  .image_gallery_overlay .slider {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    -webkit-transition: -webkit-transform 300ms ease;
    transition: -webkit-transform 300ms ease;
    transition: transform 300ms ease;
    transition: transform 300ms ease, -webkit-transform 300ms ease;
    white-space: nowrap;
    width: 100%;
  }
  .image_gallery_overlay .slider.bounce_from_right {
    -webkit-animation: image_gallery_bounce_from_right 300ms ease-out;
            animation: image_gallery_bounce_from_right 300ms ease-out;
  }
  .image_gallery_overlay .slider.bounce_from_left {
    -webkit-animation: image_gallery_bounce_from_left 300ms ease-out;
            animation: image_gallery_bounce_from_left 300ms ease-out;
  }
  .image_gallery_overlay .full_image {
    display: inline-block;
    height: 100%;
    position: relative;
    text-align: center;
    width: 100%;
  }
  .image_gallery_overlay .full_image:before {
    content: "";
    display: inline-block;
    height: 50%;
    width: 0;
  }
  .image_gallery_overlay .full_image figure {
    display: inline;
    height: 100%;
    margin: 0;
  }
  .image_gallery_overlay .full_image figcaption {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    color: #fff;
    display: block;
    padding: 0.95em;
    position: absolute;
    text-align: center;
    white-space: normal;
    width: 100%;
  }
  .image_gallery_overlay .full_image img {
    display: inline-block;
    height: auto;
    max-height: 100%;
    max-width: 100%;
    opacity: 0;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    vertical-align: middle;
    visibility: hidden;
    width: auto;
  }
  .image_gallery_overlay .full_image img.show {
    opacity: 1;
    visibility: visible;
  }
  .image_gallery_overlay .spinner {
    display: inline-block;
    height: 1.125rem;
    left: 50%;
    margin-left: -2.1875rem;
    margin-top: -0.5625rem;
    position: absolute;
    top: 50%;
    width: 4.375rem;
  }
  .image_gallery_overlay .spinner .bounce {
    -webkit-animation: image_gallery_spinner 900ms infinite;
            animation: image_gallery_spinner 900ms infinite;
    background: #d51317;
    border-radius: 100%;
    display: inline-block;
    height: 1.125rem;
    margin: 0 0.25rem;
    width: 1.125rem;
  }
  .image_gallery_overlay .spinner .bounce_1 {
    -webkit-animation-delay: -300ms;
            animation-delay: -300ms;
  }
  .image_gallery_overlay .spinner .bounce_2 {
    -webkit-animation-delay: -150ms;
            animation-delay: -150ms;
  }
  .image_gallery_overlay nav a {
    cursor: pointer;
    display: block;
    position: absolute;
  }
  .image_gallery_overlay nav .previous,
  .image_gallery_overlay nav .next {
    height: 2.75rem;
    margin-top: -1.375rem;
    top: 50%;
    -webkit-transition: background 300ms;
    transition: background 300ms;
    width: 2.75rem;
  }
  .image_gallery_overlay nav .previous:focus .icon,
  .image_gallery_overlay nav .next:focus .icon,
  .image_gallery_overlay nav .previous:hover .icon,
  .image_gallery_overlay nav .next:hover .icon {
    fill: #d51317;
  }
  .image_gallery_overlay nav .previous .icon,
  .image_gallery_overlay nav .next .icon {
    fill: #fff;
    height: 2.75rem;
    width: 2.75rem;
  }
  .image_gallery_overlay nav .previous {
    left: 2%;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .image_gallery_overlay nav .next {
    right: 2%;
  }
  .image_gallery_overlay nav .close {
    background: #fff;
    height: 2.75rem;
    padding: 0.375rem;
    right: 0;
    top: 0;
    -webkit-transition: background 300ms;
    transition: background 300ms;
    width: 2.75rem;
  }
  .image_gallery_overlay nav .close:focus,
  .image_gallery_overlay nav .close:hover {
    outline: 0;
  }
  .image_gallery_overlay nav .close:focus .icon,
  .image_gallery_overlay nav .close:hover .icon {
    fill: #d51317;
  }
  .image_gallery_overlay nav .close .icon {
    fill: #000000;
    height: 2rem;
    width: 2rem;
  }
  .img_container.arbeitnehmer_online_svg_2_small {
    max-width: 18.125rem;
  }
  .img_container.arbeitnehmer_online_svg_2_small .svg_resize {
    padding-bottom: 634.48275862%;
  }
  .img_container.arbeitnehmer_online_svg_2 {
    margin: 0 0 1.9em;
    max-width: 28.125rem;
  }
  .img_container.arbeitnehmer_online_svg_2 .svg_resize {
    padding-bottom: 148.88888889%;
  }
  .arbeitnehmer_online_svg_2_small {
    display: block;
    margin: 1.9em auto;
  }
  .arbeitnehmer_online_svg_2 {
    display: none;
  }
  .arbeitnehmer_online_2_headline {
    fill: #000000;
    font-family: "Lato", sans-serif;
    font-size: 90%;
    text-anchor: middle;
  }
  .arbeitnehmer_online_2_headline .strong {
    fill: #000000;
    font-weight: 900;
  }
  .arbeitnehmer_online_text {
    fill: #000000;
    font-family: "Lato", sans-serif;
    font-size: 90%;
    text-anchor: middle;
  }
  .arbeitnehmer_online_company_logo {
    fill: #000000;
  }
  .arbeitnehmer_online_icon {
    fill: #d51317;
    -webkit-transition: 300ms;
    transition: 300ms;
  }
  .arbeitnehmer_online_button .normal {
    display: block;
  }
  .arbeitnehmer_online_button .hover {
    display: none;
  }
  .arbeitnehmer_online_button:hover .normal {
    display: none;
  }
  .arbeitnehmer_online_button:hover .hover {
    display: block;
  }
  .arbeitnehmer_online_button:hover .arbeitnehmer_online_icon {
    fill: #d51317;
  }
  .arbeitnehmer_online_button:hover .arbeitnehmer_online_euro_icon,
  .arbeitnehmer_online_button:hover .arbeitnehmer_online_warning_icon,
  .arbeitnehmer_online_button:hover .arbeitnehmer_online_check_icon {
    fill: #d51317;
  }
  .arbeitnehmer_online_button:hover .arbeitnehmer_online_icon {
    fill: #000000;
  }
  .arbeitnehmer_online_lines {
    fill: #d51317;
  }
  .u_online_secure_login {
    margin-bottom: 1.9em;
  }
  .u_online_overview {
    margin-bottom: 3.8em;
  }
  .u_online_overview .touch_link {
    border-bottom: 0.1875rem solid #d51317;
    -webkit-box-flex: 1;
            flex: 1 0 auto;
    margin-top: 3.8em;
  }
  .u_online_overview .row > div {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
  }
  .u_online .infolist_top_link {
    display: none;
  }
  .arbeitnehmer_online_company_logo,
  .u_online_company_logo {
    -webkit-transform: translateY(-3.125rem);
            transform: translateY(-3.125rem);
  }
  .arbeitnehmer_online_company_logo .logo_2,
  .u_online_company_logo .logo_2 {
    display: none;
  }
  .img_container.u_online_svg_2_small {
    max-width: 18.125rem;
  }
  .img_container.u_online_svg_2_small .svg_resize {
    padding-bottom: 634.48275862%;
  }
  .img_container.u_online_svg_2 {
    margin: 0 0 1.9em;
    max-width: 28.125rem;
  }
  .img_container.u_online_svg_2 .svg_resize {
    padding-bottom: 148.88888889%;
  }
  .u_online_svg_2_small {
    display: block;
    margin: 1.9em auto;
  }
  .u_online_svg_2 {
    display: none;
  }
  .u_online_2_headline {
    fill: #000000;
    font-family: "Lato", sans-serif;
    font-size: 90%;
    text-anchor: middle;
  }
  .u_online_2_headline .strong {
    fill: #000000;
    font-weight: 900;
  }
  .u_online_text {
    fill: #000000;
    font-family: "Lato", sans-serif;
    font-size: 90%;
    text-anchor: middle;
  }
  .u_online_company_logo {
    fill: #000000;
  }
  .u_online_warning_icon {
    fill: #000000;
    -webkit-transition: 300ms;
    transition: 300ms;
  }
  .u_online_euro_icon,
  .u_online_check_icon {
    fill: #000000;
    -webkit-transition: 300ms;
    transition: 300ms;
  }
  .u_online_icon {
    fill: #d51317;
    -webkit-transition: 300ms;
    transition: 300ms;
  }
  .u_online_button .normal {
    display: block;
  }
  .u_online_button .hover {
    display: none;
  }
  .u_online_button:hover .normal {
    display: none;
  }
  .u_online_button:hover .hover {
    display: block;
  }
  .u_online_button:hover .u_online_icon {
    fill: #d51317;
  }
  .u_online_button:hover .u_online_euro_icon,
  .u_online_button:hover .u_online_warning_icon,
  .u_online_button:hover .u_online_check_icon {
    fill: #d51317;
  }
  .u_online_button:hover .u_online_icon {
    fill: #000000;
  }
  .u_online_lines {
    fill: #d51317;
  }
  .u_online_benefits_flow h2 {
    margin-left: auto;
    margin-right: auto;
    max-width: 30em;
    text-align: center;
  }
  .u_online_benefits_flow .table_cell.text {
    margin: 0 auto;
    max-width: 20rem;
  }
  .u_online_benefits_flow .flow_text {
    display: none;
  }
  .u_online_benefits_flow .header {
    display: table;
    margin: 0.95em auto;
    max-width: 20rem;
    text-align: right;
  }
  .u_online_benefits_flow .header .text {
    display: table-cell;
    margin: 0;
    padding: 0 0.95em 0 0;
    vertical-align: middle;
  }
  .u_online_benefits_flow .header:after {
    background: url("../images/u_online/u_online_arrow_small.svg") no-repeat center center;
    content: "";
    display: table-cell;
    height: 4rem;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    width: 1.875rem;
  }
  .u_online_benefits_flow .footer {
    display: table;
    margin: 0.95em auto;
    max-width: 20rem;
  }
  .u_online_benefits_flow .footer .text {
    display: table-cell;
    margin: 0;
    padding: 0 0 0 0.95em;
    vertical-align: middle;
  }
  .u_online_benefits_flow .footer:before {
    background: url("../images/u_online/u_online_arrow_small.svg") no-repeat center center;
    content: "";
    display: table-cell;
    height: 4rem;
    width: 1.875rem;
  }
  .u_online_benefits_flow .description {
    margin: 0 auto;
  }
  .u_online_benefits_flow .description ul {
    list-style: none;
    margin: 1.9em;
  }
  .u_online_benefits_flow .description li {
    margin: 0.9em 0;
    padding-left: 2.125rem;
    position: relative;
  }
  .u_online_benefits_flow .description li:before {
    background-image: url("../images/u_online/u_online_check.svg");
    content: "";
    display: block;
    height: 1.5rem;
    left: 0;
    position: absolute;
    width: 1.5rem;
  }
  .u_online_benefits_flow .lock {
    background: #efeae4;
    display: table;
    margin: 0 auto 0.95em;
    padding: 1.9em;
  }
  .u_online_benefits_flow .lock .text {
    display: table-cell;
    padding-left: 1.9em;
    vertical-align: middle;
  }
  .u_online_benefits_flow .lock:before {
    background: url("../images/u_online/u_online_lock.svg") no-repeat 50% 50%;
    content: "";
    display: table-cell;
    height: 1.75rem;
    width: 1.75rem;
  }
  .u_online_benefits_flow .benefits_button {
    border: 0;
    cursor: pointer;
    display: table;
    margin: 0 auto;
    min-width: 14.375rem;
    text-decoration: none;
  }
  .u_online_benefits_flow .benefits_button > span {
    display: table-cell;
  }
  .u_online_benefits_flow .benefits_button .text {
    border-bottom-width: 0.125rem;
    border-color: #d51317;
    border-left-width: 0.0625rem;
    border-radius: 0 0 0 0;
    border-style: solid;
    border-top-width: 0.0625rem;
    color: #000000;
    display: block;
    font-size: 130%;
    font-weight: 500;
    line-height: 2.75rem;
    margin-right: 0.1875rem;
    padding: 0 1.9em;
    text-align: center;
    text-transform: uppercase;
    -webkit-transition: border 300ms;
    transition: border 300ms;
  }
  .u_online_benefits_flow .benefits_button:after {
    background-color: #d51317;
    background-image: url("../images/u_online/u_online_button_arrow.svg");
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 60%;
    border-radius: 0 0 0 0;
    content: "";
    display: table-cell;
    height: 2.75rem;
    position: relative;
    -webkit-transition: background 300ms;
    transition: background 300ms;
    vertical-align: middle;
    width: 2.75rem;
    z-index: 1;
  }
  .u_online_benefits_flow .benefits_button.visible .text {
    border-color: #000000;
  }
  .u_online_benefits_flow .benefits_button.visible:after {
    background-color: #000000;
    border-radius: 0 0 0 0;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .u_online_benefits_flow .benefits_button:hover .text {
    border-color: #a60f12;
  }
  .u_online_benefits_flow .benefits_button:hover:after {
    background-color: #a60f12;
  }
  .u_online_benefits_flow .flow_image {
    border: 0.25rem solid #d51317;
    border-radius: 50%;
    box-sizing: content-box;
    height: 15.625rem;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    width: 15.625rem;
  }
  .u_online_benefits_flow .flow_image .text {
    background: rgba(213, 19, 23, 0.85);
    bottom: 0;
    color: #fff;
    left: 0;
    padding: 0.9em 0;
    position: absolute;
    text-align: center;
    width: 100%;
  }
  .u_online_benefits_flow .flow_image img {
    border-radius: 50%;
  }
  .amount {
    display: block;
    text-align: right;
    white-space: nowrap;
  }
  .multi_columns {
    -webkit-column-count: 2;
            column-count: 2;
    -webkit-column-gap: 1.9em;
            column-gap: 1.9em;
  }
  .multi_columns li {
    -webkit-column-break-inside: avoid;
            break-inside: avoid;
  }
  .infolist_top_link {
    -webkit-box-align: center;
            align-items: center;
    background-color: #efeae4;
    display: -webkit-box;
    display: flex;
    margin: 3.8em 0;
    padding: 0.1875rem;
  }
  .infolist_top_link a {
    -webkit-box-align: center;
            align-items: center;
    color: #b69f84;
    display: -webkit-box;
    display: flex;
    font-size: 80%;
    font-weight: 900;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    -webkit-transition: all 300ms;
    transition: all 300ms;
  }
  .infolist_top_link a:hover {
    color: #000000;
  }
  .infolist_top_link a:hover .icon {
    fill: #000000;
  }
  .infolist_top_link .icon {
    display: block;
    fill: #b69f84;
    height: 1.25rem;
    margin-right: 0.95em;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    -webkit-transition: all 300ms;
    transition: all 300ms;
    width: 1.25rem;
  }
  .disclaimer {
    background: #efeae4;
    border-radius: 0;
    color: #000000;
    font-size: 85%;
    margin-bottom: 0.95em;
    padding: 1.26666667em 1.9em;
  }
  .infolist_cta {
    -webkit-box-align: center;
            align-items: center;
    background: #efeae4;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-pack: center;
            justify-content: center;
    margin: 3.8em 0;
    padding: 3.8em;
    text-align: center;
    text-decoration: none;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    color: #000000;
  }
  .infolist_cta:hover,
  .infolist_cta:focus {
    background: #b69f84;
  }
  .infolist_download {
    -webkit-box-align: center;
            align-items: center;
    background: #efeae4;
    border-radius: 0;
    color: #000000;
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
            justify-content: space-between;
    margin-bottom: 1.9em;
    padding: 1.9em;
  }
  .infolist_download .infolist_title {
    font-weight: 900;
  }
  .infolist_download p {
    margin-right: 1.9em;
  }
  .checklist_entry {
    border-top: 0.0625rem solid #dcd1c4;
    margin-bottom: 0.95em;
    padding-top: 0.95em;
  }
  .checklist_entry:first-child {
    border-top: 0;
    padding-top: 0;
  }
  .infolist_overview,
  .useful_link_overview,
  .onlinetools_popup_overview,
  .video_infolists_overview {
    border-top: 0.0625rem solid #b69f84;
    margin-bottom: 1.9em;
  }
  .infolist_overview {
    border-top: 0;
  }
  .infolist_auto main .row .border_top {
    display: none;
  }
  .img_container.infolist_graphic_meine_steuern_graphic_large {
    display: none;
    margin: 0.95em auto;
    width: 37.5rem;
  }
  .img_container.infolist_graphic_meine_steuern_graphic_large .svg_resize {
    padding-bottom: 96.2890625%;
  }
  .img_container.infolist_graphic_meine_steuern_graphic_small {
    margin: 3.8em auto;
    width: 4000rem;
  }
  .img_container.infolist_graphic_meine_steuern_graphic_small .svg_resize {
    padding-bottom: 503.80952381%;
  }
  .meine_steuern_text {
    fill: #000000;
    font-family: "Lato", sans-serif;
  }
  .meine_steuern_arrows {
    fill: #d51317;
  }
  .meine_steuern_icon {
    fill: #000000;
  }
  .infolist_graphic_meine_steuern_graphic_large svg stop:nth-child(1) {
    stop-color: #fbd2d3;
  }
  .infolist_graphic_meine_steuern_graphic_large svg stop:nth-child(2) {
    stop-color: #fff;
  }
  .newsletter_quick_subscribe form {
    display: -webkit-box;
    display: flex;
  }
  .newsletter_quick_subscribe .entry {
    width: 100%;
  }
  .newsletter_quick_subscribe input[type="email"] {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    background: #fff;
    border-bottom-width: 0.0625rem;
    border-color: #dcd1c4;
    border-left-width: 0.0625rem;
    border-radius: 0 0 0 0;
    border-style: solid;
    border-top-width: 0.0625rem;
    box-shadow: 0 0 0.1875rem #dcd1c4 inset;
    color: #000000;
    height: 2.75rem;
    margin: 0;
    padding: 0 0.95em;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    width: 100%;
  }
  .newsletter_quick_subscribe input[type="email"]:focus {
    background: #fff;
    border-color: transparent;
    box-shadow: 0 0 0.1875rem transparent inset, 0 0 0 0.1875rem rgba(0, 0, 0, 0.2);
    color: #000000;
    outline: 0;
  }
  .newsletter_quick_subscribe button {
    border-radius: 0 0 0 0;
    margin: 0;
    padding: 0 1.9em;
    background: transparent;
    border: 0.0625rem solid transparent;
    color: #000000;
    display: inline-block;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .newsletter_quick_subscribe button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    height: calc(2.75rem - (0.0625rem * 2));
    -webkit-box-pack: center;
            justify-content: center;
  }
  .newsletter_quick_subscribe button .icon {
    fill: #000000;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .newsletter_quick_subscribe button .text {
    font-weight: 900;
    padding: 0.63333333em 0;
    white-space: nowrap;
  }
  .newsletter_quick_subscribe button .icon + .text {
    padding-left: 0.63333333em;
  }
  .newsletter_quick_subscribe button:focus {
    box-shadow: 0 0 0 0.1875rem rgba(213, 19, 23, 0.2);
    background: transparent;
    border-color: transparent;
    color: #000000;
  }
  .newsletter_quick_subscribe button:focus .icon {
    fill: #000000;
  }
  .toggle_offcanvas_menu {
    display: inline-block;
    position: absolute;
    right: calc(0.9375rem + 2.1875
                                     * (100vw - 20rem)
                                     / (93.75 - 20));
    top: 4.375rem;
  }
  .toggle_offcanvas_menu label {
    -webkit-box-align: center;
            align-items: center;
    cursor: pointer;
    display: -webkit-box;
    display: flex;
    font-size: 112.5%;
    font-weight: 900;
    height: 2.75rem;
    position: relative;
    text-transform: uppercase;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    z-index: 10;
  }
  .toggle_offcanvas_menu label .text {
    position: relative;
    -webkit-transition: all 600ms;
    transition: all 600ms;
  }
  .toggle_offcanvas_menu label::before {
    background-color: #d51317;
    bottom: 0;
    content: "";
    display: block;
    height: 0.1875rem;
    left: 0;
    position: absolute;
    right: 0;
    -webkit-transition: all 600ms;
    transition: all 600ms;
  }
  .toggle_offcanvas_menu label::after {
    background-color: #d51317;
    bottom: 0;
    content: "";
    display: block;
    height: 0.1875rem;
    left: 0;
    position: absolute;
    right: 0;
    -webkit-transition: all 600ms;
    transition: all 600ms;
  }
  .toggle_offcanvas_menu a {
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .toggle_offcanvas_menu .open_offcanvas_menu {
    display: block;
  }
  .toggle_offcanvas_menu .close_offcanvas_menu {
    display: none;
  }
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .toggle_offcanvas_menu label::before,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .toggle_offcanvas_menu label::before {
    -webkit-transform: translateY(-0.625rem) rotate(45deg);
            transform: translateY(-0.625rem) rotate(45deg);
  }
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .toggle_offcanvas_menu label::after,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .toggle_offcanvas_menu label::after {
    display: block;
    -webkit-transform: translateY(-0.625rem) rotate(-45deg);
            transform: translateY(-0.625rem) rotate(-45deg);
  }
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .toggle_offcanvas_menu label .text,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .toggle_offcanvas_menu label .text {
    opacity: 0;
    -webkit-transform: translateX(6.25rem);
            transform: translateX(6.25rem);
  }
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .toggle_offcanvas_menu .open_offcanvas_menu,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .toggle_offcanvas_menu .open_offcanvas_menu {
    display: none;
  }
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .toggle_offcanvas_menu .close_offcanvas_menu,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .toggle_offcanvas_menu .close_offcanvas_menu {
    display: block;
  }
  .offcanvas_menu_wrapper {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
            flex-direction: row-reverse;
    min-height: 100vh;
    min-width: 20rem;
    overflow-x: hidden;
    position: relative;
    width: 100%;
    z-index: 5000;
    flex-direction: row;
  }
  .offcanvas_menu_wrapper .page {
    -webkit-box-flex: 1;
            flex: 1 0 100%;
    min-height: 100vh;
    position: relative;
    -webkit-transition: -webkit-transform 300ms;
    transition: -webkit-transform 300ms;
    transition: transform 300ms;
    transition: transform 300ms, -webkit-transform 300ms;
  }
  .offcanvas_menu_wrapper .offcanvas_menu {
    -webkit-transition: all 300ms;
    transition: all 300ms;
  }
  .offcanvas_menu_overlay {
    -webkit-backdrop-filter: blur(0.125rem);
    background: rgba(0, 0, 0, 0.45);
    bottom: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    visibility: hidden;
    z-index: 110;
  }
  .offcanvas_menu {
    background: #fff !important;
    background-color: #fff !important;
    -webkit-box-flex: 1;
            flex: 1 0 15.625rem;
    opacity: 0;
    padding: 1.9em 0;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    visibility: hidden;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li {
    border-bottom: 0.1875rem solid #d51317;
    padding: 0 1.9em;
    position: relative;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li.expanded > a ~ ul {
    display: block;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li > a {
    color: #000000;
    display: block;
    font-size: 112.5%;
    font-weight: 900;
    line-height: 2.75rem;
    text-decoration: none;
    text-transform: uppercase;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li > a.active {
    color: #d51317;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul {
    display: none;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul:target {
    display: block;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul a {
    color: #000000;
    display: block;
    padding: 0.475em 1.9em 0.475em 0;
    text-decoration: none;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul a.active {
    color: #d51317;
  }
  .onlinetools_page .onlinetools_popup_overview {
    border-top: 0;
  }
  .onlinetools_page .onlinetools_popup_overview .entry {
    background-color: #efeae4;
    display: -webkit-box;
    display: flex;
    -webkit-box-flex: 1;
            flex: 1 0 auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    margin-bottom: 1.9em;
    padding: 1.9em;
  }
  .onlinetools_page .onlinetools_popup_overview .entry .button {
    align-self: flex-end;
    margin-top: auto;
  }
  .popup_overlay {
    -webkit-backdrop-filter: blur(0.125rem);
    background: rgba(0, 0, 0, 0.45);
    bottom: 0;
    left: 0;
    opacity: 0;
    padding: 1% 0;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    visibility: hidden;
    z-index: -1;
  }
  .popup_overlay.show {
    opacity: 1;
    visibility: visible;
    z-index: 9000;
  }
  .popup_header {
    padding: 0 0 0 1.9em;
  }
  .popup_close {
    background: #d51317;
    border-radius: 0 0 0 0;
    float: right;
    height: 2.2rem;
    margin-right: 0.0625rem;
    padding: 0.54285714em;
    text-align: center;
    width: 2.75rem;
  }
  .popup_close .icon {
    fill: #fff;
    height: 1.5rem;
    width: 1.5rem;
  }
  .popup_content {
    height: 100%;
    margin: 0 auto;
    max-width: 100rem;
    opacity: 0;
    position: relative;
    -webkit-transform: scale(0.6);
            transform: scale(0.6);
    -webkit-transition: all 300ms;
    transition: all 300ms;
    visibility: hidden;
    z-index: 9500;
  }
  .popup_content.show {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
    visibility: visible;
  }
  .popup_content .content {
    background: #fff;
    bottom: 0;
    box-shadow: 0 0 1.5625rem rgba(0, 0, 0, 0.5);
    left: 0;
    position: absolute;
    right: 0;
    top: 2.2rem;
  }
  .popup_iframe {
    background: #fff;
    border: 0;
    height: 100%;
    opacity: 0;
    position: relative;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    width: 100%;
    z-index: 1;
  }
  .popup_iframe.show {
    opacity: 1;
  }
  .preloader {
    display: block;
    position: relative;
  }
  .preloader .icon_spin {
    -webkit-animation: preloader_rotate 900ms linear infinite;
            animation: preloader_rotate 900ms linear infinite;
    display: block;
    fill: #dcd1c4;
    height: 3rem;
    left: 50%;
    margin: -1.5rem 0 0 -1.5rem;
    opacity: 1;
    position: absolute;
    top: 50%;
    -webkit-transition: 300ms;
    transition: 300ms;
    visibility: visible;
    width: 3rem;
  }
  .preloader .icon_spin.hide {
    opacity: 0;
    visibility: hidden;
  }
  .no-cssanimations .icon_spin {
    display: none;
  }
  .toggle_search_box {
    height: 2.125rem;
    position: relative;
    width: 2.125rem;
  }
  .toggle_search_box label {
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10;
  }
  .toggle_search_box label:hover ~ a {
    color: #000000;
  }
  .toggle_search_box a {
    fill: #000000;
  }
  .toggle_search_box a .icon {
    display: block;
    height: 2.125rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 2.125rem;
  }
  .toggle_search_box .open_search_box {
    display: -webkit-box;
    display: flex;
  }
  .toggle_search_box .close_search_box {
    display: none;
  }
  .search_box {
    -webkit-backdrop-filter: blur(0.125rem);
    -webkit-box-align: start;
            align-items: flex-start;
    bottom: 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    left: 0;
    opacity: 0;
    padding: 0;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    visibility: hidden;
    z-index: 9999;
  }
  .search_box .close_search_box_wrapper {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .search_box .close_search_box_wrapper label {
    background: rgba(0, 0, 0, 0.45);
    bottom: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    visibility: hidden;
  }
  .search_box .close_search_box_wrapper .close_search_box {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    fill: #b69f84;
    height: 2.75rem;
    padding: 0.375rem;
    position: absolute;
    right: 0;
    top: 0;
    width: 2.75rem;
    z-index: 1100;
  }
  .search_box .close_search_box_wrapper .close_search_box .icon {
    display: block;
    height: 2.125rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 2.125rem;
  }
  .search_box .close_search_box_wrapper .close_search_box:focus,
  .search_box .close_search_box_wrapper .close_search_box:hover {
    outline: 0;
  }
  .search_box .close_search_box_wrapper .close_search_box:focus .icon,
  .search_box .close_search_box_wrapper .close_search_box:hover .icon {
    fill: #d51317;
  }
  .search_box form[role="search"] {
    -webkit-box-align: center;
            align-items: center;
    background: #fff;
    border: 0.625rem solid #fff;
    display: -webkit-box;
    display: flex;
    height: 7.5rem;
    opacity: 0;
    padding: 2.0625rem 0.95em 0 0.95em;
    position: relative;
    -webkit-transform: translateY(-6.25rem);
            transform: translateY(-6.25rem);
    -webkit-transition: none;
    transition: none;
    visibility: hidden;
    width: 100vw;
    z-index: 1000;
  }
  .search_box form[role="search"] button {
    -webkit-box-flex: 0;
            flex: 0 1 3.75rem;
    height: 2.75rem;
    padding: 0 0.475em;
  }
  .search_box input[type="search"] {
    -webkit-box-flex: 1;
            flex: 1 1 auto;
    font-size: 125%;
  }
  .search_box_state {
    left: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    visibility: hidden;
  }
  .search_box_state:checked ~ .search_box,
  .search_box_state:target ~ .search_box {
    opacity: 1;
    visibility: visible;
  }
  .search_box_state:checked ~ .search_box .close_search_box_wrapper label,
  .search_box_state:target ~ .search_box .close_search_box_wrapper label {
    opacity: 1;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    visibility: visible;
  }
  .search_box_state:checked ~ .search_box form[role="search"],
  .search_box_state:target ~ .search_box form[role="search"] {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-transition: -webkit-transform 300ms;
    transition: -webkit-transform 300ms;
    transition: transform 300ms;
    transition: transform 300ms, -webkit-transform 300ms;
    visibility: visible;
  }
  .search_box_state:checked ~ .offcanvas_menu_wrapper .toggle_search_box .open_search_box,
  .search_box_state:target ~ .offcanvas_menu_wrapper .toggle_search_box .open_search_box {
    display: none;
  }
  .search_box_state:checked ~ .offcanvas_menu_wrapper .toggle_search_box .close_search_box,
  .search_box_state:target ~ .offcanvas_menu_wrapper .toggle_search_box .close_search_box {
    display: -webkit-box;
    display: flex;
  }
  form[role="search"] {
    display: -webkit-box;
    display: flex;
  }
  form[role="search"] button {
    border-radius: 0;
    margin: 0;
    min-width: 2.75rem;
    padding: 0;
    width: 2.75rem;
    background: #efeae4;
    border: 0.0625rem solid #efeae4;
    color: #000000;
    display: inline-block;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  form[role="search"] button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    height: calc(2.75rem - (0.0625rem * 2));
    -webkit-box-pack: center;
            justify-content: center;
  }
  form[role="search"] button .icon {
    fill: #000000;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  form[role="search"] button .text {
    font-weight: 900;
    padding: 0.63333333em 0;
    white-space: nowrap;
  }
  form[role="search"] button .icon + .text {
    padding-left: 0.63333333em;
  }
  form[role="search"] button[disabled] {
    background: #efeae4;
    border: 0.0625rem solid #e6ded4;
    color: #808080;
    display: inline-block;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  form[role="search"] button[disabled] > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    height: calc(2.75rem - (0.0625rem * 2));
    -webkit-box-pack: center;
            justify-content: center;
  }
  form[role="search"] button[disabled] .icon {
    fill: #808080;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  form[role="search"] button[disabled] .text {
    font-weight: 900;
    padding: 0.63333333em 0;
    white-space: nowrap;
  }
  form[role="search"] button[disabled] .icon + .text {
    padding-left: 0.63333333em;
  }
  form[role="search"] button:focus {
    box-shadow: 0 0 0 0.1875rem rgba(239, 234, 228, 0.2);
    position: relative;
    z-index: 10;
    background: transparent;
    border-color: #d51317;
    color: #d51317;
  }
  form[role="search"] button:focus .icon {
    fill: #d51317;
  }
  form[role="search"] button .icon {
    height: calc(2.75rem - (0.0625rem * 2));
  }
  input[type="search"] {
    -moz-appearance: none;
    -webkit-appearance: none;
    background: #faf9f7;
    border: 0.0625rem solid transparent;
    border-bottom: 0.1875rem solid #d51317;
    border-radius: 0 0 0 0;
    box-shadow: 0 0 0.1875rem #fff inset;
    color: #000000;
    flex-basis: 100%;
    height: 2.75rem;
    margin: 0;
    padding: 0 0.95em;
    -webkit-transition: all 300ms;
    transition: all 300ms;
  }
  input[type="search"]:focus {
    background: #fff;
    border-color: #efeae4;
    color: #000000;
    outline: 0;
    position: relative;
    z-index: 10;
  }
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }
  .search dl {
    margin: 0 0 0.95em;
    padding-top: 0.475em;
  }
  .search dl br,
  .search dl em,
  .search dl img {
    display: none;
  }
  .search dd {
    margin: 0;
  }
  .social_media_links {
    position: absolute;
    top: 4.56em;
    left: 1.9em;
    z-index: 9999;
  }
  .social_media_links ul {
    display: -webkit-box;
    display: flex;
    margin: 0;
    padding: 0;
  }
  .social_media_links li {
    list-style: none;
    margin: 0;
  }
  .social_media_links a {
    border-radius: 0;
    display: inline-block;
  }
  .social_media_links a .icon {
    display: -webkit-box !important;
    display: flex !important;
    fill: #fff;
    height: 2.125rem;
    width: 2.125rem;
    -webkit-box-pack: center !important;
            justify-content: center !important;
    -webkit-box-align: center !important;
            align-items: center !important;
  }
  .social_media_links a.facebook {
    border-radius: 50%;
  }
  .social_media_links a.twitter {
    background: #1da1f2;
    border-radius: 50%;
  }
  .social_media_links a.video {
    background: #ce3b00;
    border-radius: 50%;
  }
  .social_media_links a.linkedin {
    background: #0a66c2;
    border-radius: 50%;
  }
  .social_media_links a.linkedin svg {
    height: 1.5625rem;
    width: 1.5625rem;
  }
  .social_media_links a.instagram {
    background: #D73576;
    border-radius: 50%;
  }
  .social_media_links a.instagram svg {
    height: 1.5625rem;
    width: 1.5625rem;
  }
  .social_media_share {
    display: block;
    margin-left: -0.5rem;
    position: relative;
  }
  .startpage .social_media_share {
    display: none;
  }
  .social_media_share .icon {
    display: block;
    fill: #fff;
    height: 1.375rem;
    width: 1.375rem;
  }
  .social_media_share .share_and_print {
    letter-spacing: calc((0.75rem + 0.125
                                 * (100vw - 20rem)
                                 / (93.75 - 20))
                                 / 1000 * 80);
    font-size: 0.75rem;
    -webkit-box-align: center;
            align-items: center;
    color: #000000;
    display: none;
    margin-left: 0.625rem;
    min-width: 22.5rem;
    position: absolute;
    text-decoration: none;
    text-transform: uppercase;
    top: -1.875rem;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    -webkit-transform-origin: top left;
            transform-origin: top left;
  }
  .social_media_share .share_and_print .icon {
    display: -webkit-box;
    display: flex;
    fill: #000000;
    height: 0.625rem;
    margin-right: 1.9em;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-transition: -webkit-transform 300ms;
    transition: -webkit-transform 300ms;
    transition: transform 300ms;
    transition: transform 300ms, -webkit-transform 300ms;
    width: 6.25rem;
  }
  .social_media_share ul {
    display: -webkit-box;
    display: flex;
    padding: 0;
  }
  .social_media_share li {
    height: 2.75rem;
    list-style: none;
    margin: 0;
    padding: 0;
    width: 2.75rem;
  }
  .social_media_share a {
    -webkit-box-align: center;
            align-items: center;
    background-color: #b69f84;
    border: 0.5rem solid #fff;
    display: -webkit-box;
    display: flex;
    height: 2.75rem;
    -webkit-box-pack: center;
            justify-content: center;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    width: 2.75rem;
  }
  .social_media_share a:hover.facebook,
  .social_media_share a:focus.facebook {
    background-color: #1877F2;
  }
  .social_media_share a:hover.twitter,
  .social_media_share a:focus.twitter {
    background-color: #1da1f2;
  }
  .social_media_share a:hover.instagram,
  .social_media_share a:focus.instagram {
    background-color: #D73576;
  }
  .social_media_share a:hover.print,
  .social_media_share a:focus.print {
    background-color: #d51317;
  }
  .social_media_share a:hover.linkedin,
  .social_media_share a:focus.linkedin {
    background: #0a66c2;
  }
  .social_media_legal_notice {
    padding: 1.9em;
  }
  .social_media_legal_notice .company_logo {
    margin: 0 auto;
    text-align: center;
  }
  .legal_notice_privacy_policy_social_media_use h4 {
    font-weight: bold;
  }
  .steuernews_article_slider {
    display: none;
  }
  .steuernews_navigation {
    margin: 1.9em 0;
  }
  .steuernews_navigation nav ul:after {
    clear: both;
  }
  .steuernews_navigation nav ul:after,
  .steuernews_navigation nav ul:before {
    content: "";
    display: table;
  }
  .steuernews_navigation nav li {
    float: left;
  }
  .steuernews_navigation nav li:first-child a {
    padding-left: 0;
  }
  .steuernews_navigation nav a {
    display: inline-block;
    line-height: 1.9;
    padding: 0 1.9em;
  }
  .steuernews_content [href*="thema"] {
    color: #000000;
  }
  .steuernews_preview {
    margin-bottom: 0.95em;
  }
  .steuernews_preview .entry {
    display: -webkit-box;
    display: flex;
    -webkit-box-flex: 0;
            flex: 0 0 auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    margin-bottom: 3.8em;
  }
  .steuernews_preview .image {
    -webkit-box-flex: 0;
            flex: 0 0 auto;
    margin: 0;
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .steuernews_preview .image img {
    margin-right: 1.9em;
  }
  .steuernews_preview .teaser_text {
    background-color: #efeae4;
    border-bottom: 0.1875rem solid #d51317;
    display: -webkit-box;
    display: flex;
    -webkit-box-flex: 1;
            flex: 1 0 auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    margin-top: 0.95em;
    -webkit-box-ordinal-group: 3;
            order: 2;
    padding: 1.9em;
  }
  .steuernews_preview .teaser_text .sub_title {
    color: #000000;
    display: block;
    font-size: 80%;
    margin-top: 0.3125rem;
  }
  .steuernews_preview .teaser_text h3 {
    text-decoration: none;
  }
  .steuernews_preview .video_play {
    position: relative;
  }
  .steuernews_preview .video_play .icon {
    fill: #d51317;
    height: 2.75rem;
    left: 0;
    margin: -1.375rem 0 0 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%;
  }
  .steuernews_all_issues {
    border-bottom: 0.1875rem solid #d51317;
    border-top: 0.1875rem solid #d51317;
    margin: 1.9em 0;
    padding: 1.9em 0;
  }
  .steuernews_search {
    margin: 0 0 1.9em;
  }
  .steuernews_search .search {
    margin: 0 0 0.95em;
  }
  .news_entity .hide_creation_date {
    display: none;
  }
  .news_image {
    position: relative;
  }
  .news_image::after {
    background-color: #d51317;
    bottom: 0;
    content: "";
    display: block;
    height: 0.1875rem;
    left: 0;
    right: 0;
  }
  .news_image img {
    display: block;
  }
  .news_image + * {
    padding-top: 0;
  }
  .steuernews_video_overview {
    margin-bottom: 1.9em;
  }
  .steuernews_video_overview .entry {
    display: inline-block;
  }
  .steuernews_video_overview .image {
    margin-bottom: 0.95em;
    position: relative;
  }
  .steuernews_video_overview .video_play {
    position: relative;
  }
  .steuernews_video_overview .video_play .icon {
    fill: #d51317;
    height: 2.75rem;
    left: 0;
    margin: -1.375rem 0 0 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%;
  }
  .steuernews_contact_person {
    background: #efeae4;
    border-radius: 0;
    margin-bottom: 1.9em;
    padding: 1.9em;
  }
  .steuernews_contact_person .team_entry {
    display: -webkit-box;
    display: flex;
  }
  .steuernews_contact_person .team_entry figure {
    display: block;
  }
  .steuernews_contact_person .contact_person_entry {
    height: auto;
    margin-top: 1.9em;
    position: relative;
  }
  .steuernews_contact_person .more_team_contacts {
    background: #fff;
    border-radius: 0;
    color: #d51317;
    display: block;
    line-height: 1.9;
    margin: 1em 0;
    padding: 0.95em;
    text-align: center;
  }
  .steuernews_newsletter_subscribe {
    background: #efeae4;
    border-radius: 0;
    margin-bottom: 1.9em;
    padding: 1.9em;
  }
  .steuernews_newsletter_subscribe .disclaimer {
    background-color: #fff;
  }
  .steuernews_sidebar {
    margin: 1.9em 0 0.95em;
    padding-top: 0;
  }
  .steuernews_current_issue {
    margin-bottom: 0.95em;
  }
  .keyword_register {
    margin-bottom: 3.8em;
  }
  .keyword_register:after {
    clear: both;
  }
  .keyword_register:after,
  .keyword_register:before {
    content: "";
    display: table;
  }
  .keyword_register a {
    background: #efeae4;
    float: left;
    font-weight: 900;
    line-height: 2.75rem;
    margin: 0 0.95em 0.95em 0;
    text-align: center;
    text-decoration: none;
    width: 2.75rem;
  }
  .keyword_register a:hover {
    color: #b69f84;
  }
  .steuernews_keywords ul,
  .steuernews_issues ul {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
  .steuernews_keywords a,
  .steuernews_issues a {
    background: #efeae4;
    border-radius: 0;
    display: inline-block;
    line-height: 2.75rem;
    margin: 0 0.95em 0.95em 0;
    padding: 0 1.9em;
  }
  .steuernews_keywords_register .keywords_register_entries a {
    display: inline-block;
    max-width: 12.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .steuernews_call_to_action {
    background: #efeae4;
    border-radius: 0;
    display: inline-block;
    margin: 0 0 1.9em;
    padding: 0.95em;
  }
  .steuernews_call_to_action p {
    margin: 0;
  }
  .highlight_keyword {
    background: #d51317;
    color: #fff;
    outline: 0.125rem solid #d51317;
  }
  .team_overview .entry {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
            flex-direction: column-reverse;
    margin-bottom: 3.8em;
  }
  .team_overview .title {
    margin: 0;
    padding: 0;
  }
  .team_overview .photo {
    -webkit-box-flex: 0;
            flex: 0 0 auto;
    margin: 0 0 0.95em 0;
  }
  .team_overview .photo img {
    display: block;
  }
  .team_overview .info {
    -webkit-box-flex: 0;
            flex: 0 0 auto;
    margin-bottom: 0.95em;
  }
  .team_overview .buttons .call {
    margin-right: 1.9em;
  }
  .team_entry .photo {
    margin-bottom: 0.95em;
    max-width: 8.75rem;
  }
  .team_entry .photo img {
    display: block;
  }
  .team_entry .info {
    margin-bottom: 0.95em;
  }
  .tooltip_wrapper {
    display: block;
    position: relative;
  }
  [role="tooltip"] {
    background: #efeae4;
    border: 0.0625rem solid #dcd1c4;
    border-radius: 0;
    color: #000000;
    font-size: 85%;
    font-weight: 300;
    line-height: 2.0625rem;
    padding: 0 0.95em;
    position: absolute;
    right: 0;
    text-align: center;
    top: -2.4375rem;
    z-index: 50;
  }
  [role="tooltip"]:after {
    border: 0.625rem solid transparent;
    border-top-color: #efeae4;
    bottom: -1.25rem;
    content: "";
    display: block;
    height: 0;
    left: 50%;
    margin-left: -0.625rem;
    position: absolute;
    width: 0;
  }
  [role="tooltip"]:before {
    border: 0.6875rem solid transparent;
    border-top-color: #dcd1c4;
    bottom: -1.375rem;
    content: "";
    display: block;
    height: 0;
    left: 50%;
    margin-left: -0.6875rem;
    position: absolute;
    width: 0;
  }
  [role="tooltip"][aria-hidden="true"] {
    display: none;
  }
  .video_overview .entry,
  .video_infolists_overview .entry {
    display: -webkit-box;
    display: flex;
    -webkit-box-flex: 0;
            flex: 0 0 auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    margin-bottom: 1.9em;
  }
  .video_overview .image,
  .video_infolists_overview .image {
    display: -webkit-box;
    display: flex;
    -webkit-box-flex: 0;
            flex: 0 0 auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    margin: 0.95em 0;
  }
  .video_overview .image .description,
  .video_infolists_overview .image .description {
    letter-spacing: calc((0.875rem + 0.125
                                 * (100vw - 20rem)
                                 / (93.75 - 20))
                                 / 1000 * 0);
    font-size: 0.875rem;
    background-color: #efeae4;
    border-bottom: 0.1875rem solid #d51317;
    -webkit-box-flex: 0;
            flex: 0 0 auto;
    font-weight: 900;
    margin-top: 0.95em;
    padding: 1.9em;
    text-transform: uppercase;
  }
  .video_overview .video_play,
  .video_infolists_overview .video_play {
    -webkit-box-flex: 0;
            flex: 0 0 auto;
    position: relative;
  }
  .video_overview .video_play .icon,
  .video_infolists_overview .video_play .icon {
    fill: #d51317;
    height: 2.75rem;
    left: 0;
    margin: -1.375rem 0 0 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%;
  }
  .video_wrapper {
    height: 0;
    margin-bottom: 1.9em;
    padding-top: 56.25%;
    position: relative;
  }
  .video_wrapper video,
  .video_wrapper iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .video_wrapper video::cue {
    background: #000000;
    color: #ffffff;
  }
  a.button.video_channel {
    background: #ce3b00;
    border-color: #681e00;
    color: #fff;
  }
  a.button.video_channel .icon {
    fill: #fff;
  }
  .video_preview {
    margin-bottom: 0.95em;
  }
  .video_preview .entry {
    display: -webkit-box;
    display: flex;
    margin-bottom: 1.9em;
  }
  .video_preview .image {
    -webkit-box-flex: 0;
            flex: 0 0 auto;
    margin: 0;
    -webkit-box-ordinal-group: 2;
            order: 1;
    padding-right: 1.9em;
  }
  .video_preview .teaser_text {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .video_preview .video_play {
    position: relative;
  }
  .video_preview .video_play .icon {
    fill: #d51317;
    height: 2.75rem;
    left: 0;
    margin: -1.375rem 0 0 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%;
  }
  .startpage h1 {
    font-weight: 300;
    margin: 3.8em 0 1.9em 0;
    text-transform: none;
  }
  .startpage h1 .company_name {
    display: block;
    font-weight: 900;
    margin-right: 0.5rem;
    text-transform: uppercase;
  }
  .startpage h1 .intro::after {
    background-color: #d51317;
    content: "";
    display: block;
    height: 0.0625rem;
    margin: 0.95em 0;
    width: 7.5rem;
  }
  .startpage .intro .introtext p {
    margin-bottom: 0;
  }
  .startpage .intro .container .row > div:last-of-type .bg_container {
    background-color: #efeae4;
    display: none;
    height: 100%;
    position: relative;
  }
  .startpage .intro .container .row > div:last-of-type .bg_container::after {
    background-color: #efeae4;
    bottom: 0;
    content: "";
    display: block;
    position: absolute;
    right: -500%;
    top: 0;
    width: 500%;
  }
  .startpage .headline {
    -webkit-box-align: end;
            align-items: flex-end;
    display: -webkit-box;
    display: flex;
    height: 100%;
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .startpage .headline h2 {
    letter-spacing: calc((1.1875rem + 0.25
                                 * (100vw - 20rem)
                                 / (93.75 - 20))
                                 / 1000 * 0);
    font-size: 1.1875rem;
    display: block;
    margin: 0;
    position: relative;
    text-align: left;
    text-transform: none;
  }
  .startpage .headline h2::after {
    background-color: #d51317;
    bottom: -0.95em;
    content: "";
    display: block;
    height: 0.0625rem;
    left: 0;
    position: absolute;
    right: 20%;
  }
  .startpage .headline h2 strong {
    display: block;
    text-transform: uppercase;
  }
  .startpage_figure {
    height: 100%;
    margin: 0;
    position: relative;
    z-index: 1;
  }
  .startpage_figure figcaption {
    letter-spacing: calc((0.875rem + 0.125
                                 * (100vw - 20rem)
                                 / (93.75 - 20))
                                 / 1000 * 0);
    font-size: 0.875rem;
    background-color: rgba(255, 255, 255, 0.7);
    bottom: 0;
    font-weight: 900;
    margin: 0;
    max-width: 80%;
    padding: 0.95em 0.95em 0;
    position: absolute;
    right: 0;
    text-transform: uppercase;
  }
  .startpage_figure figcaption p {
    margin: 0;
  }
  .teaser_text {
    margin-top: 3.8em;
  }
  .startpage .steuerberatung_teaser {
    margin-top: 7.6em;
  }
  .startpage .steuerberatung_teaser .container .row > div:first-child {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .startpage .steuerberatung_teaser .container .row > div:nth-child(2) {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .startpage .steuerberatung_teaser .container .row > div:nth-child(3) {
    height: 100%;
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .startpage .steuerberatung_teaser .container .row > div:nth-child(4) {
    height: 100%;
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .startpage .bruttonetto_teaser,
  .startpage .newsletter_teaser,
  .startpage .kontaktdaten_teaser {
    background-color: #efeae4;
    margin: 3.8em 0;
    padding: 3.8em 0;
    position: relative;
  }
  .startpage .bruttonetto_teaser h3,
  .startpage .newsletter_teaser h3,
  .startpage .kontaktdaten_teaser h3 {
    padding: 0;
  }
  .startpage .bruttonetto_teaser::before,
  .startpage .newsletter_teaser::before,
  .startpage .kontaktdaten_teaser::before {
    background-color: #efeae4;
    bottom: 0;
    content: "";
    display: block;
    left: -30vw;
    position: absolute;
    top: 0;
    width: 30vw;
  }
  .startpage .bruttonetto_teaser::after,
  .startpage .newsletter_teaser::after,
  .startpage .kontaktdaten_teaser::after {
    background-color: #efeae4;
    bottom: 0;
    content: "";
    display: block;
    position: absolute;
    right: -500%;
    top: 0;
    width: 500%;
  }
  .startpage .bruttonetto_teaser::before {
    background-color: #efeae4;
    bottom: 0;
    content: "";
    display: block;
    left: unset;
    right: -30vw;
    position: absolute;
    top: 0;
    width: 30vw;
  }
  .startpage .bruttonetto_teaser::after {
    background-color: #efeae4;
    bottom: 0;
    content: "";
    display: block;
    position: absolute;
    right: unset;
    left: -500%;
    top: 0;
    width: 500%;
  }
  .startpage .newsletter_teaser::before {
    background-color: #efeae4;
    bottom: 0;
    content: "";
    display: block;
    left: -500%;
    position: absolute;
    top: 0;
    width: 500%;
  }
  .startpage .newsletter_teaser::after {
    background-color: #efeae4;
    bottom: 0;
    content: "";
    display: block;
    position: absolute;
    right: -30vw;
    top: 0;
    width: 30vw;
  }
  .startpage .newsletter_teaser h3 {
    margin-bottom: 0;
  }
  .startpage .herausforderung_teaser .text {
    -webkit-box-align: end;
            align-items: flex-end;
    display: -webkit-box;
    display: flex;
    height: 100%;
  }
  .startpage .herausforderung_teaser .text p {
    margin: 1.9em 0 1.26666667em 0;
  }
  .startpage .beratung_teaser {
    margin-top: 3.8em;
  }
  .startpage .beratung_teaser .headline {
    -webkit-box-pack: start;
            justify-content: flex-start;
    padding-bottom: 2.875rem;
  }
  .startpage .beratung_teaser .headline h2 {
    text-align: left;
  }
  .startpage .beratung_teaser .headline h2:after {
    left: 0;
    right: 20%;
  }
  .startpage .beratung_teaser .teaser_text {
    margin-top: 0;
  }
  .startpage .beratung_teaser .links_overview {
    display: -webkit-box;
    display: flex;
  }
  .startpage .beratung_teaser .links_overview ul {
    -webkit-column-count: 3;
            column-count: 3;
    display: -webkit-box;
    display: flex;
    -webkit-box-flex: 100%;
            flex: 100%;
    flex-wrap: wrap;
    margin-top: 0.95em;
    padding: 0;
    padding-top: 1.9em;
    position: relative;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
  }
  .startpage .beratung_teaser .links_overview ul::before {
    background-color: #d51317;
    content: "";
    display: block;
    height: 0.0625rem;
    right: 0;
    position: absolute;
    top: 0;
    width: 500%;
  }
  .startpage .beratung_teaser .links_overview li {
    -webkit-box-flex: unset;
            flex: unset;
    list-style: none;
    padding-left: 0;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    margin-bottom: 0.95em;
    /*@media (min-width: @small_grid_breakpoint) {
                flex: 50%;
            }

            @media (min-width: @medium_grid_breakpoint) {
                flex: 33.333333%;
            }

            @media (min-width: @large_grid_breakpoint) {
                flex: 50%;
            }

            @media (min-width: @xxlarge_grid_breakpoint) {
                flex: 33.333333%;
            }*/
  }
  .startpage .beratung_teaser .links_overview li a {
    color: #000000;
    text-decoration: none;
  }
  .startpage .beratung_teaser .links_overview li a:hover {
    color: #d51317;
    text-decoration: underline;
  }
  .startpage .kontakt_teaser {
    margin-top: 7.6em;
  }
  .startpage .kontakt_teaser .container .row > div {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
  }
  .startpage .kontakt_teaser .kontaktdaten_teaser {
    margin: 1.9em 0;
  }
  .startpage .kontakt_teaser .kontaktdaten_teaser::before {
    background-color: #efeae4;
    bottom: 0;
    content: "";
    display: block;
    left: -500%;
    position: absolute;
    top: 0;
    width: 500%;
  }
  .startpage .kontakt_teaser .startpage_figure {
    height: 100%;
    max-width: 31.25rem !important;
    width: 100%;
  }
  .open_street_map .map {
    background-color: #efeae4;
    cursor: move;
    height: 21.875rem;
    position: relative;
    width: 100%;
  }
  .open_street_map .map_overlay {
    -webkit-box-align: center;
            align-items: center;
    background-color: rgba(239, 234, 228, 0.85);
    bottom: 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    z-index: 1;
  }
  .open_street_map .map_overlay.show {
    opacity: 1;
    -webkit-transition-delay: 0;
            transition-delay: 0;
  }
  .open_street_map .map_overlay.show p {
    font-size: 120%;
    margin: 1.9em 3.8em;
    text-align: center;
  }
  .open_street_map .required_description {
    margin: 1.9em 0 0 0;
  }
  .open_street_map .form_disclaimer {
    margin: 1.9em 0 0 0;
  }
  .open_street_map .plan_route {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    flex-wrap: wrap;
    margin: 1.9em 0;
    position: relative;
  }
  .open_street_map .plan_route .saddr {
    -webkit-box-flex: 1;
            flex-grow: 1;
    flex-shrink: 1;
  }
  .open_street_map .plan_route .select_wrapper {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-flex: 1;
            flex-grow: 1;
    flex-shrink: 1;
    margin-top: 0.95em;
  }
  .open_street_map .plan_route .form_disclaimer {
    margin-top: 1.9em;
  }
  .open_street_map .ol-viewport {
    touch-action: pan-y !important;
  }
  .open_street_map .ol-overlaycontainer-stopevent button {
    -webkit-box-align: center;
            align-items: center;
    background: transparent;
    border: 0.0625rem solid transparent;
    border-radius: 0;
    color: #000000;
    display: -webkit-inline-box;
    display: inline-flex;
    font-weight: 900;
    height: 2.75rem;
    -webkit-box-pack: center;
            justify-content: center;
    margin: 0;
    padding: 0;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    width: 2.75rem;
  }
  .open_street_map .ol-overlaycontainer-stopevent button:focus {
    background: transparent;
    border: 0.0625rem solid transparent;
    box-shadow: 0 0 0 0.1875rem rgba(213, 19, 23, 0.2);
    color: #000000;
    outline: 0;
  }
  .open_street_map .ol-overlaycontainer-stopevent button:hover {
    background: transparent;
    border: 0.0625rem solid transparent;
    color: #000000;
  }
  .open_street_map .ol-overlaycontainer-stopevent button.disabled {
    cursor: default;
    opacity: 0.6;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution,
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-in,
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-out,
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoomslider {
    position: absolute;
    right: 0.3125rem;
    z-index: 99;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-in {
    line-height: 1;
    top: 0.3125rem;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-out {
    line-height: 1;
    top: 3.375rem;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-rotate-reset {
    display: none;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution {
    -webkit-box-align: center;
            align-items: center;
    bottom: 0;
    cursor: default;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: end;
            justify-content: flex-end;
    right: 0;
    text-align: right;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    z-index: 99;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution ul {
    -webkit-box-align: center;
            align-items: center;
    background: rgba(255, 255, 255, 0.85);
    color: black;
    display: -webkit-box;
    display: flex;
    height: 2.75rem;
    -webkit-box-pack: end;
            justify-content: flex-end;
    list-style: none !important;
    margin: 0;
    padding: 0;
    text-shadow: 0 0 0.125rem rgba(0, 0, 0, 0.15);
    -webkit-transform-origin: center right;
            transform-origin: center right;
    -webkit-transition: opacity 300ms, -webkit-transform 300ms;
    transition: opacity 300ms, -webkit-transform 300ms;
    transition: transform 300ms, opacity 300ms;
    transition: transform 300ms, opacity 300ms, -webkit-transform 300ms;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution ul li {
    -webkit-box-align: center;
            align-items: center;
    background-image: none !important;
    display: -webkit-box;
    display: flex;
    font-size: 85%;
    margin: 0 0.95em;
    padding: 0;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution ul li a {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-inline-box;
    display: inline-flex;
    margin: 0 0.3125rem;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution button {
    display: none;
  }
  .open_street_map.disable_ui .ol-overlaycontainer-stopevent .ol-zoom-in,
  .open_street_map.disable_ui .ol-overlaycontainer-stopevent .ol-zoom-out {
    display: none;
  }
  .open_street_map .form_disclaimer {
    background: #efeae4;
    border-radius: 0;
    color: #000000;
    margin-bottom: 0.95em;
    padding: 1.26666667em 1.9em;
  }
  .content_slider {
    position: relative;
  }
  .content_slider .slide_wrapper {
    display: -webkit-box;
    display: flex;
    overflow: hidden;
    position: relative;
  }
  .content_slider .slide_wrapper .slide {
    padding-right: 2.5rem;
    padding-left: 2.5rem;
    -webkit-box-flex: 1;
            flex: 1 0 100%;
    max-width: 100%;
    -webkit-transition: -webkit-transform 0.3s;
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
    z-index: 10;
    display: -webkit-box;
    display: flex;
    overflow: hidden;
  }
  .content_slider .slide_wrapper .slide .team_entry_wrapper {
    background-color: #d51317;
    margin-top: 7.22em;
    margin-bottom: 6.08em;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    -webkit-box-flex: 1;
            flex-grow: 1;
  }
  .content_slider .slide_wrapper .slide .team_entry_wrapper .photo {
    -webkit-box-flex: 0;
            flex: 0 0 48%;
    align-self: center;
    max-width: 48%;
    padding-left: 1.9em;
  }
  .content_slider .slide_wrapper .slide .team_entry_wrapper .info {
    -webkit-box-flex: 0;
            flex: 0 0 52%;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-pack: center;
            justify-content: center;
  }
  .content_slider .slide_wrapper .slide .team_entry_wrapper .info.no_photo {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-flex: 1;
            flex-grow: 1;
    -webkit-box-pack: center;
            justify-content: center;
    -webkit-box-align: center;
            align-items: center;
    padding-left: 0;
    margin-left: 0;
  }
  .content_slider .slide_wrapper .slide .team_entry_wrapper .info.no_photo .title {
    margin: 1.9em;
  }
  .content_slider .slide_wrapper .slide .team_entry_wrapper .info.no_photo .buttons {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .content_slider .slide_wrapper .slide .team_entry_wrapper .info ul {
    width: 80%;
  }
  .content_slider .navigation_wrapper {
    display: -webkit-box;
    display: flex;
    position: relative;
    z-index: 30;
    padding-top: 1.9em;
  }
  .content_slider .dot {
    display: none;
  }
  .content_slider .slide_prev,
  .content_slider .slide_next {
    background-image: url("../../common/images/icons/arrows.svg");
    background-repeat: no-repeat;
    bottom: 0;
    cursor: pointer;
    display: block;
    opacity: 1;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    -webkit-transition: all 300ms;
    transition: all 300ms;
    width: 2.5rem;
    height: 2.5rem;
    z-index: 20;
  }
  .content_slider .slide_prev.hidden,
  .content_slider .slide_next.hidden {
    cursor: default;
    opacity: 0;
  }
  .content_slider .slide_prev {
    left: 0;
    -webkit-transform: translateY(-50%) rotate(180deg);
            transform: translateY(-50%) rotate(180deg);
  }
  .content_slider .slide_next {
    right: 0;
  }
  .karriere_slider {
    margin-top: 1.9em;
  }
  .karriere_slider .slide {
    color: #000000;
    text-decoration: none !important;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
  }
  .karriere_slider .slide h3,
  .karriere_slider .slide p {
    text-decoration: none;
  }
  .karriere_slider .slide p {
    color: #000000;
  }
  .karriere_slider .slide figure {
    display: -webkit-box;
    display: flex;
    align-self: center;
  }
  .karriere_slider .slide figure img {
    max-width: 25rem;
  }
  .karriere_slider .slide_prev {
    opacity: 1 !important;
  }
  .karriere_slider .go_to_story {
    color: #d51317 !important;
  }
  .karriere_slider .go_to_story:hover {
    text-decoration: underline !important;
  }
  .kanzlei_im_netz {
    margin: 1.9em 0;
  }
  .kanzlei_im_netz .touch_link {
    background: #efeae4;
    box-sizing: border-box;
    color: #000000;
    -webkit-box-flex: 1;
            flex-grow: 1;
    margin-bottom: 1.9em;
    padding: 0.95em 1.9em;
    position: relative;
    height: 100%;
  }
  .kanzlei_im_netz .touch_link .icon_linkedin {
    background: #0a66c2;
    border-radius: 50%;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    -webkit-box-align: center;
            align-items: center;
  }
  .kanzlei_im_netz .touch_link .icon_linkedin svg {
    height: 2.5rem;
    width: 2.5rem;
  }
  .kanzlei_im_netz .touch_link .icon_instagram-logo {
    background: #D73576;
    border-radius: 50%;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    -webkit-box-align: center;
            align-items: center;
  }
  .kanzlei_im_netz .touch_link .icon_instagram-logo svg {
    height: 2.5rem;
    width: 2.5rem;
  }
  .kanzlei_im_netz a.touch_link:hover {
    background: #fff;
    color: #000000;
    -webkit-transition: background 300ms ease;
    transition: background 300ms ease;
  }
  .kanzlei_im_netz a.touch_link:hover .title {
    color: #000000;
  }
  .kanzlei_im_netz .title_wrapper {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: start;
            justify-content: flex-start;
    margin-bottom: 0.95em;
  }
  .kanzlei_im_netz .title_wrapper .title {
    color: #000000;
    margin: 0;
    padding: 0;
    text-decoration: none;
  }
  .kanzlei_im_netz .description h2 {
    color: #000000;
  }
  .kanzlei_im_netz .icon {
    fill: #ffffff;
    height: 3.625rem;
    margin-right: 1.9em;
    padding: 0.3125rem;
    width: 3.625rem;
  }
  .kanzlei_im_netz .icon svg {
    height: 3rem;
    width: 3rem;
  }
  .kanzlei_im_netz .icon_vimeo {
    background: #ce3b00;
    fill: #fff;
  }
  .teaser_benefits {
    background-color: #efeae4;
    padding: 1.9em 1.9em 0 1.9em;
  }
  .teaser_benefits .teaser {
    background-color: #fff;
    position: relative;
  }
  .teaser_benefits .teaser:hover .title .text_container {
    opacity: 1;
  }
  .teaser_benefits .teaser .title {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    position: relative;
    padding-top: 0;
  }
  .teaser_benefits .teaser .title .icon svg {
    height: 4.375rem;
    margin: 1.52em;
    width: 4.375rem;
  }
  .teaser_benefits .teaser .title .text_container {
    -webkit-box-align: center;
            align-items: center;
    background-color: rgba(213, 19, 23, 0.95);
    display: -webkit-box;
    display: flex;
    height: 100%;
    -webkit-box-pack: center;
            justify-content: center;
    left: 50%;
    opacity: 0;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    -webkit-transition: 450ms;
    transition: 450ms;
    width: 100%;
  }
  .teaser_benefits .teaser .title .text_container .text {
    color: #fff;
    margin: 0 0.475em;
    text-align: center;
  }
}
@media screen and (min-width: 20rem) {
  .container {
    padding: 0 calc(0.9375rem + 2.1875
                                     * (100vw - 20rem)
                                     / (93.75 - 20));
  }
}
@media screen and (min-width: 93.75em) {
  .container {
    padding: 0 3.125rem;
  }
}
@media screen and (min-width: 20rem) {
  .row {
    margin: 0 calc((-0.9375rem / 2) - (2.1875 / 2)
                                     * (100vw - 20rem)
                                     / (93.75 - 20));
  }
}
@media screen and (min-width: 93.75em) {
  .row {
    margin: 0 -1.5625rem;
  }
}
@media screen and (min-width: 20rem) {
  .row.half_column_margin {
    margin: 0 calc((-0.9375rem / 4) - (2.1875 / 4)
                                     * (100vw - 20rem)
                                     / (93.75 - 20));
  }
}
@media screen and (min-width: 93.75em) {
  .row.half_column_margin {
    margin: 0 -0.78125rem;
  }
}
@media screen and (min-width: 20rem) {
  .row.half_column_margin > [class*="span"] {
    padding: 0 calc((0.9375rem / 4) + (2.1875 / 4)
                                     * (100vw - 20rem)
                                     / (93.75 - 20));
  }
}
@media screen and (min-width: 93.75em) {
  .row.half_column_margin > [class*="span"] {
    padding: 0 0.78125rem;
  }
}
@media screen and (min-width: 30em) {
  .row.around_small {
    justify-content: space-around;
  }
  .row.between_small {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_small {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_small {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_small {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_small {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_small {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_small {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_small {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 35em) {
  .row.around_medium {
    justify-content: space-around;
  }
  .row.between_medium {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_medium {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_medium {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_medium {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_medium {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_medium {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_medium {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_medium {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 47.5em) {
  .row.around_large {
    justify-content: space-around;
  }
  .row.between_large {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_large {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_large {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_large {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_large {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_large {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_large {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_large {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 61.875em) {
  .row.around_xlarge {
    justify-content: space-around;
  }
  .row.between_xlarge {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xlarge {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xlarge {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xlarge {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xlarge {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xlarge {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xlarge {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xlarge {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 75em) {
  .row.around_xxlarge {
    justify-content: space-around;
  }
  .row.between_xxlarge {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xxlarge {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xxlarge {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xxlarge {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xxlarge {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xxlarge {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xxlarge {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xxlarge {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 93.75em) {
  .row.around_xxxlarge {
    justify-content: space-around;
  }
  .row.between_xxxlarge {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xxxlarge {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xxxlarge {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xxxlarge {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xxxlarge {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xxxlarge {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xxxlarge {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xxxlarge {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 20rem) {
  [class*="span"] {
    padding: 0 calc((0.9375rem / 2) + (2.1875 / 2)
                                     * (100vw - 20rem)
                                     / (93.75 - 20));
  }
}
@media screen and (min-width: 93.75em) {
  [class*="span"] {
    padding: 0 1.5625rem;
  }
}
@media screen and (min-width: 30em) {
  .span1_small {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_small {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_small {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_small {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_small {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_small {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_small {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_small {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_small {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_small {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_small {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_small {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_small {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_small {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_small {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_small {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_small {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_small {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_small {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_small {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_small {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_small {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_small {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_small {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 35em) {
  .span1_medium {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_medium {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_medium {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_medium {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_medium {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_medium {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_medium {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_medium {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_medium {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_medium {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_medium {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_medium {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_medium {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_medium {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_medium {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_medium {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_medium {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_medium {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_medium {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_medium {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_medium {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_medium {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_medium {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_medium {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 47.5em) {
  .span1_large {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_large {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_large {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_large {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_large {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_large {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_large {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_large {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_large {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_large {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_large {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_large {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_large {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_large {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_large {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_large {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_large {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_large {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_large {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_large {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_large {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_large {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_large {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_large {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 61.875em) {
  .span1_xlarge {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xlarge {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xlarge {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xlarge {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xlarge {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xlarge {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xlarge {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xlarge {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xlarge {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xlarge {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xlarge {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xlarge {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xlarge {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xlarge {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xlarge {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xlarge {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xlarge {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xlarge {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xlarge {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xlarge {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xlarge {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xlarge {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xlarge {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xlarge {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 75em) {
  .span1_xxlarge {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xxlarge {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xxlarge {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xxlarge {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xxlarge {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xxlarge {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xxlarge {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xxlarge {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xxlarge {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xxlarge {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xxlarge {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xxlarge {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xxlarge {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xxlarge {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xxlarge {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xxlarge {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xxlarge {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xxlarge {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xxlarge {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xxlarge {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xxlarge {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xxlarge {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xxlarge {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xxlarge {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 93.75em) {
  .span1_xxxlarge {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xxxlarge {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xxxlarge {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xxxlarge {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xxxlarge {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xxxlarge {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xxxlarge {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xxxlarge {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xxxlarge {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xxxlarge {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xxxlarge {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xxxlarge {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xxxlarge {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xxxlarge {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xxxlarge {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xxxlarge {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xxxlarge {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xxxlarge {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xxxlarge {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xxxlarge {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xxxlarge {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xxxlarge {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xxxlarge {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xxxlarge {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 75em) {
  .navigation_wrapper {
    -webkit-box-align: end;
            align-items: flex-end;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
}
@media screen and (min-width: 47.5em) {
  .go_to_top {
    display: -webkit-box;
    display: flex;
  }
}
@media screen and (min-width: 47.5em) {
  .icon_container {
    left: 0;
    position: fixed;
    right: 0;
    z-index: 4000;
  }
}
@media screen and (min-width: 30em) {
  .icon_wrapper {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
}
@media screen and (min-width: 47.5em) {
  .icon_wrapper {
    margin-top: 0;
  }
}
@media screen and (min-width: 30em) {
  .icon_wrapper .toggle_search_box,
  .icon_wrapper .go_to_contact,
  .icon_wrapper .go_to_video {
    padding: 0 0.95em;
  }
  .icon_wrapper .toggle_search_box a .text,
  .icon_wrapper .go_to_contact a .text,
  .icon_wrapper .go_to_video a .text {
    padding-left: 0.95em;
  }
}
@media screen and (min-width: 47.5em) {
  .author_hor {
    display: none;
  }
}
@media screen and (min-width: 47.5em) {
  .author_ver {
    display: block;
    position: absolute;
    right: 0.1875rem;
    top: 3.7rem;
  }
}
@media screen and (min-width: 47.5em) {
  .header_image {
    padding-right: 10vw;
  }
}
@media screen and (min-width: 75em) {
  .header_image {
    padding-right: 18vw;
  }
}
@media screen and (min-width: 47.5em) {
  .header_background {
    margin-right: 10vw;
    padding-bottom: 29.5% !important;
  }
}
@media screen and (min-width: 75em) {
  .header_background {
    margin-right: 18vw;
  }
}
@media screen and (min-width: 47.5em) {
  .startpage .header_background {
    padding-bottom: 42.5% !important;
  }
}
@media screen and (min-width: 20rem) {
  .header_image_wrapper .go_to_contents {
    font-size: calc(0.75rem + 0.125
                            * (100vw - 20rem)
                            / (93.75 - 20));
  }
}
@media screen and (min-width: 93.75em) {
  .header_image_wrapper .go_to_contents {
    font-size: 0.875rem;
  }
}
@media screen and (min-width: 47.5em) {
  .header_image_wrapper .go_to_contents {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
  }
}
@media screen and (min-width: 75em) {
  .header_image_wrapper .go_to_contents {
    right: 17vw;
    width: 37vw;
  }
}
@media screen and (min-width: 47.5em) {
  .imprint main {
    max-width: 60vw;
  }
}
@media screen and (min-width: 75em) {
  main {
    padding-top: 7.6em;
  }
}
@media screen and (min-width: 35em) {
  .company_logo {
    max-width: 15.625rem;
  }
}
@media screen and (min-width: 47.5em) {
  .company_logo {
    display: none;
  }
  .company_logo.company_logo_2 {
    display: block;
    margin: 0 auto;
  }
}
@media screen and (min-width: 75em) {
  .company_logo {
    display: block;
    min-width: 15.625rem;
  }
  .company_logo.company_logo_2 {
    display: none;
  }
}
@media screen and (min-width: 75em) {
  .breadcrumbs li {
    display: block;
  }
  .breadcrumbs li::after {
    display: block;
    height: 1.3125rem;
    margin-left: 0.625rem;
    margin-right: 0;
    width: 0.0625rem;
  }
}
@media screen and (min-width: 75em) {
  .breadcrumbs {
    position: absolute;
    left: 82vw;
    margin: 0;
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .breadcrumbs ol {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
  }
}
@media screen and (min-width: 35em) {
  aside .entry {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
  }
  aside .entry figure {
    height: 100%;
    max-width: 40vw !important;
    min-width: 40vw;
    -webkit-transform: translate(0, -1.9em);
            transform: translate(0, -1.9em);
  }
}
@media screen and (min-width: 61.875em) {
  aside .entry {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
  }
  aside .entry figure {
    height: 100%;
    max-width: none;
    min-width: 0;
    -webkit-transform: translate(1.9em, -3.8em);
            transform: translate(1.9em, -3.8em);
  }
}
@media screen and (min-width: 61.875em) {
  aside .entry.sidebar_teaser {
    padding-bottom: 6.25rem;
  }
}
@media screen and (min-width: 35em) {
  aside .entry.newsletter {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
            flex-direction: row-reverse;
  }
}
@media screen and (min-width: 61.875em) {
  aside .entry.newsletter {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
  }
}
@media screen and (min-width: 47.5em) {
  .footer_address {
    -webkit-box-align: end;
            align-items: flex-end;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
  }
}
@media screen and (min-width: 93.75em) {
  .footer_address .contact_wrapper > p {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
  }
}
@media screen and (min-width: 93.75em) {
  .footer_address .contact_wrapper > p > span {
    margin-right: 0;
  }
  .footer_address .contact_wrapper > p > span::after {
    content: "<";
    display: inline-block;
    margin: 0 0.95em;
  }
}
@media screen and (min-width: 47.5em) {
  footer .icon_wrapper {
    -webkit-box-align: center;
            align-items: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
  }
}
@media screen and (min-width: 47.5em) {
  footer .icon_wrapper .related_links ul {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    margin: 0;
  }
}
@media screen and (min-width: 61.875em) {
  .edit_in_cms {
    bottom: 1.25rem;
    display: inline-block;
    background: #d51317;
    border-radius: 0;
    color: #fff;
    padding: 0.95em 1.9em;
    position: fixed;
    right: 1.25rem;
    text-decoration: none;
    -webkit-transition: background 300ms;
    transition: background 300ms;
    z-index: 9999;
  }
  .edit_in_cms:hover {
    background: #000000;
    color: #fff;
  }
}
@media screen and (min-width: 30em) {
  body.cb_visible .page {
    margin-bottom: 6.5625rem;
  }
}
@media screen and (min-width: 35em) {
  body.cb_visible .page {
    margin-bottom: 5.3125rem;
  }
}
@media screen and (min-width: 75em) {
  body.cb_visible .page {
    margin-bottom: 4.0625rem;
  }
}
@media screen and (min-width: 47.5em) {
  .dropdown_menu {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
  }
}
@media screen and (min-width: 35em) {
  .google_maps .step:before {
    font-size: inherit;
  }
}
@media screen and (min-width: 35em) {
  .google_maps .instructions,
  .google_maps .distance {
    font-size: inherit;
  }
}
@media screen and (min-width: 47.5em) {
  .img_container.arbeitnehmer_online_svg_2_small {
    display: none;
  }
}
@media screen and (min-width: 47.5em) {
  .img_container.arbeitnehmer_online_svg_2 {
    display: block;
  }
}
@media screen and (min-width: 47.5em) {
  .arbeitnehmer_online_2_headline {
    font-size: 80%;
  }
}
@media screen and (min-width: 47.5em) {
  .arbeitnehmer_online_text {
    font-size: 80%;
  }
}
@media screen and (min-width: 47.5em) {
  .img_container.u_online_svg_2_small {
    display: none;
  }
}
@media screen and (min-width: 47.5em) {
  .img_container.u_online_svg_2 {
    display: block;
  }
}
@media screen and (min-width: 47.5em) {
  .u_online_2_headline {
    font-size: 80%;
  }
}
@media screen and (min-width: 47.5em) {
  .u_online_text {
    font-size: 80%;
  }
}
@media screen and (min-width: 93.75em) {
  .u_online_benefits_flow .table {
    display: table;
    margin: 0 auto;
    padding: 0;
  }
  .u_online_benefits_flow .table_cell {
    display: table-cell;
    padding: 0 1.9em;
    vertical-align: middle;
  }
  .u_online_benefits_flow .table_cell.text {
    max-width: 31.25rem;
  }
  .u_online_benefits_flow .flow_text {
    display: block;
  }
  .u_online_benefits_flow .header {
    display: block;
    text-align: center;
  }
  .u_online_benefits_flow .header .text {
    display: block;
    margin: 0 auto 0.95em;
    padding: 0;
    text-align: center;
    width: 80%;
  }
  .u_online_benefits_flow .header:after {
    background: url("../images/u_online/u_online_arrow.svg") no-repeat;
    display: block;
    height: 0;
    margin: 0 auto;
    padding-bottom: 16.15853659%;
    -webkit-transform: rotate(0);
            transform: rotate(0);
    width: auto;
  }
  .u_online_benefits_flow .footer {
    display: block;
    text-align: center;
  }
  .u_online_benefits_flow .footer .text {
    display: block;
    margin: 0.95em auto 0;
    padding: 0;
    text-align: center;
    width: 80%;
  }
  .u_online_benefits_flow .footer:before {
    background: url("../images/u_online/u_online_arrow.svg") no-repeat;
    content: "";
    display: block;
    height: 0;
    margin: 0 auto 0.9em;
    padding-bottom: 16.15853659%;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    width: auto;
  }
  .u_online_benefits_flow .description {
    max-height: none;
    overflow: none;
    padding: 0 0.9em;
  }
  .u_online_benefits_flow .lock {
    margin-bottom: 1.9em;
  }
  .u_online_benefits_flow .lock:before {
    height: 3.5rem;
    width: 3.5rem;
  }
  .u_online_benefits_flow .benefits_button {
    display: none;
  }
}
@media screen and (min-width: 47.5em) {
  .infolist_overview,
  .useful_link_overview,
  .onlinetools_popup_overview,
  .video_infolists_overview {
    padding-top: 1.9em;
    padding-bottom: 1.9em;
    border-top: 0;
    margin-bottom: 0;
  }
}
@media screen and (min-width: 47.5em) {
  .infolist_overview,
  .onlinetools_popup_overview {
    padding-right: 1.9em;
  }
}
@media screen and (min-width: 47.5em) {
  .useful_link_overview,
  .video_infolists_overview {
    padding-left: 1.9em;
  }
}
@media screen and (min-width: 47.5em) {
  .img_container.infolist_graphic_meine_steuern_graphic_large {
    display: block;
  }
}
@media screen and (min-width: 47.5em) {
  .img_container.infolist_graphic_meine_steuern_graphic_small {
    display: none;
  }
}
@media screen and (min-width: 47.5em) {
  .toggle_offcanvas_menu {
    display: none;
  }
}
@media screen and (max-width: 47.4375em) {
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .page,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .page,
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .offcanvas_menu,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .offcanvas_menu {
    opacity: 1;
    -webkit-transform: translate(15.625rem, 0);
            transform: translate(15.625rem, 0);
    visibility: visible;
    -webkit-transform: translate(-15.625rem, 0);
            transform: translate(-15.625rem, 0);
  }
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .offcanvas_menu_content,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .offcanvas_menu_content {
    opacity: 1;
    -webkit-transition: none;
    transition: none;
    visibility: visible;
  }
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .offcanvas_menu_overlay,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .offcanvas_menu_overlay {
    opacity: 1;
    visibility: visible;
  }
}
@media screen and (max-width: 47.4375em) and (min-width: 30em) {
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .page,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .page,
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .offcanvas_menu,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .offcanvas_menu {
    -webkit-transform: translate(21.875rem, 0);
            transform: translate(21.875rem, 0);
  }
}
@media screen and (max-width: 47.4375em) and (min-width: 30em) {
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .page,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .page,
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .offcanvas_menu,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .offcanvas_menu {
    -webkit-transform: translate(-21.875rem, 0);
            transform: translate(-21.875rem, 0);
  }
}
@media screen and (min-width: 30em) {
  .offcanvas_menu {
    -webkit-box-flex: 1;
            flex: 1 0 21.875rem;
  }
}
@media screen and (min-width: 30em) {
  .search_box .close_search_box_wrapper .close_search_box {
    height: 7.5rem;
  }
}
@media screen and (min-width: 30em) {
  .search_box form[role="search"] {
    padding: 0 15vw;
  }
}
@media screen and (min-width: 61.875em) {
  .search_box form[role="search"] {
    padding: 0 23vw;
  }
}
@media screen and (min-width: 35em) {
  .social_media_links {
    top: 0.3125rem;
  }
}
@media screen and (min-width: 47.5em) {
  .social_media_links {
    top: 0.3125rem;
  }
}
@media screen and (min-width: 75em) {
  .social_media_share {
    left: 0;
    max-width: 3.75rem;
    position: absolute;
    top: 76.5625rem;
  }
}
@media screen and (min-width: 47.5em) {
  .startpage .social_media_share {
    display: block;
  }
  .startpage .social_media_share .share_and_print {
    display: -webkit-box;
    display: flex;
  }
  .startpage .social_media_share ul {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
  }
}
@media screen and (min-width: 47.5em) {
  .startpage .social_media_share {
    bottom: 31.25rem;
    top: auto;
  }
}
@media screen and (min-width: 75em) {
  .startpage .social_media_share {
    bottom: 43.75rem;
  }
}
@media screen and (min-width: 20rem) {
  .social_media_share .share_and_print {
    font-size: calc(0.75rem + 0.125
                            * (100vw - 20rem)
                            / (93.75 - 20));
  }
}
@media screen and (min-width: 93.75em) {
  .social_media_share .share_and_print {
    font-size: 0.875rem;
  }
}
@media screen and (min-width: 75em) {
  .social_media_share .share_and_print {
    display: -webkit-box;
    display: flex;
  }
}
@media screen and (min-width: 75em) {
  .social_media_share ul {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
  }
}
@media screen and (min-width: 75em) {
  .news_entity + .social_media_share {
    top: 106.25rem;
  }
}
@media screen and (min-width: 61.875em) {
  .steuernews_article_slider {
    display: block;
    margin-bottom: 3.8em;
    height: 16.125rem;
    overflow: hidden;
    position: relative;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .steuernews_article_slider .slide {
    display: -webkit-box;
    display: flex;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    visibility: hidden;
    z-index: 0;
  }
  .steuernews_article_slider .slide .image {
    min-width: 24.1875rem;
    width: 24.1875rem;
  }
  .steuernews_article_slider .slide .description {
    padding: 0 5.7em 0 3.8em;
    width: 100%;
  }
  .steuernews_article_slider .slide .description .title {
    margin: 0;
  }
  .steuernews_article_slider .slide .description .sub_title {
    display: block;
    margin-bottom: 0.95em;
  }
  .steuernews_article_slider .slide_anchor:checked + .slide {
    opacity: 1;
    visibility: visible;
    z-index: 5;
  }
  .steuernews_article_slider .control_slides {
    background: #efeae4;
    display: block;
    fill: #000000;
    height: 3.75rem;
    margin-top: -1.875rem;
    padding: 1.26666667em;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transition: background 300ms;
    transition: background 300ms;
    width: 3.75rem;
    z-index: 15;
  }
  .steuernews_article_slider .control_slides:focus,
  .steuernews_article_slider .control_slides:hover {
    fill: #d51317;
    outline: 0;
  }
  .steuernews_article_slider .prev_slide {
    left: 0;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .steuernews_article_slider .next_slide {
    right: 0;
  }
}
@media screen and (min-width: 47.5em) {
  .steuernews_all_issues {
    display: -webkit-box;
    display: flex;
  }
  .steuernews_all_issues .steuernews_current_issue {
    -webkit-box-flex: 0;
            flex: 0 0 50%;
    padding-right: 1.9em;
  }
  .steuernews_all_issues .steuernews_issues {
    -webkit-box-flex: 0;
            flex: 0 0 50%;
  }
}
@media screen and (min-width: 35em) {
  .news_image {
    float: left;
    margin-right: 1.9em;
    margin-left: -1.9em;
    max-width: 50vw;
  }
}
@media screen and (min-width: 47.5em) {
  .news_image {
    max-width: 40vw;
    margin-left: -2.5rem;
  }
}
@media screen and (min-width: 61.875em) {
  .news_image {
    max-width: 30vw;
  }
}
@media screen and (min-width: 75em) {
  .news_image {
    margin-left: -3.75rem;
  }
}
@media screen and (min-width: 93.75em) {
  .news_image {
    max-width: 25rem;
  }
}
@media screen and (min-width: 35em) {
  .team_overview .entry {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
            flex-direction: row-reverse;
  }
}
@media screen and (min-width: 93.75em) {
  .team_overview .entry {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
            flex-direction: row-reverse;
  }
}
@media screen and (min-width: 35em) {
  .team_overview .row > div:nth-of-type(2n+1) .entry {
    -webkit-box-align: start;
            align-items: flex-start;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
  }
}
@media screen and (min-width: 35em) {
  .team_overview .photo {
    max-width: 12.5rem;
    margin-right: 1.9em;
    margin-left: 1.9em;
  }
}
@media screen and (min-width: 75em) {
  .team_overview .photo {
    max-width: 25rem;
    -webkit-box-flex: 0;
            flex: 0 1 auto;
    width: 100%;
  }
}
@media screen and (min-width: 20rem) {
  .video_overview .image .description,
  .video_infolists_overview .image .description {
    font-size: calc(0.875rem + 0.125
                            * (100vw - 20rem)
                            / (93.75 - 20));
  }
}
@media screen and (min-width: 93.75em) {
  .video_overview .image .description,
  .video_infolists_overview .image .description {
    font-size: 1rem;
  }
}
@media screen and (min-width: 75em) {
  .startpage h1 {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
  }
  .startpage h1 .intro {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
  }
  .startpage h1 .intro::after {
    margin: 0 0.95em;
  }
}
@media screen and (min-width: 61.875em) {
  .startpage .intro .container .row > div:last-of-type .bg_container {
    display: block;
  }
}
@media screen and (min-width: 20rem) {
  .startpage .headline h2 {
    font-size: calc(1.1875rem + 0.25
                            * (100vw - 20rem)
                            / (93.75 - 20));
  }
}
@media screen and (min-width: 93.75em) {
  .startpage .headline h2 {
    font-size: 1.4375rem;
  }
}
@media screen and (min-width: 47.5em) {
  .startpage .headline h2::after {
    left: 20%;
    right: 0;
  }
}
@media screen and (min-width: 47.5em) {
  .startpage .headline {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .startpage .headline h2 {
    text-align: right;
  }
}
@media screen and (min-width: 20rem) {
  .startpage_figure figcaption {
    font-size: calc(0.875rem + 0.125
                            * (100vw - 20rem)
                            / (93.75 - 20));
  }
}
@media screen and (min-width: 93.75em) {
  .startpage_figure figcaption {
    font-size: 1rem;
  }
}
@media screen and (min-width: 30em) {
  .startpage_figure figcaption {
    background-color: #fff;
  }
}
@media screen and (min-width: 47.5em) {
  .startpage_figure figcaption {
    background-color: rgba(255, 255, 255, 0.7);
  }
}
@media screen and (min-width: 61.875em) {
  .startpage_figure figcaption {
    background-color: #fff;
  }
}
@media screen and (min-width: 93.75em) {
  .startpage_figure figcaption {
    max-width: 60%;
  }
}
@media screen and (min-width: 47.5em) {
  .teaser_text {
    margin-top: 1.9em;
  }
}
@media screen and (min-width: 47.5em) {
  .startpage .steuerberatung_teaser .container .row > div:first-child {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
}
@media screen and (min-width: 47.5em) {
  .startpage .steuerberatung_teaser .container .row > div:nth-child(2) {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
}
@media screen and (min-width: 61.875em) {
  .startpage .bruttonetto_teaser,
  .startpage .newsletter_teaser,
  .startpage .kontaktdaten_teaser {
    margin: 7.6em 0 -5.625rem 0;
  }
}
@media screen and (min-width: 75em) {
  .startpage .bruttonetto_teaser,
  .startpage .newsletter_teaser,
  .startpage .kontaktdaten_teaser {
    margin-bottom: -8.125rem;
  }
}
@media screen and (min-width: 47.5em) {
  .startpage .herausforderung_teaser .text p {
    margin: 1.9em 0 0 0.95em;
  }
}
@media screen and (min-width: 75em) {
  .startpage .herausforderung_teaser .text p {
    margin: 1.9em 0 0 0;
  }
}
@media screen and (min-width: 47.5em) {
  .startpage .beratung_teaser {
    padding-bottom: 0;
  }
}
@media screen and (min-width: 47.5em) {
  .startpage .beratung_teaser .teaser_text {
    margin-top: 1.9em;
  }
}
@media screen and (min-width: 93.75em) {
  .startpage .beratung_teaser .links_overview ul {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
  }
}
@media screen and (min-width: 93.75em) {
  .startpage .beratung_teaser .links_overview li {
    -webkit-box-flex: 33.333333%;
            flex: 33.333333%;
    margin-bottom: unset;
  }
}
@media screen and (min-width: 47.5em) {
  .startpage .kontakt_teaser .kontaktdaten_teaser {
    margin: 0;
  }
}
@media screen and (min-width: 47.5em) {
  .open_street_map .map {
    height: 34.375rem;
  }
}
@media screen and (min-width: 47.5em) {
  .open_street_map .plan_route {
    -webkit-box-align: end;
            align-items: flex-end;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
  }
}
@media screen and (min-width: 47.5em) {
  .open_street_map .plan_route .saddr {
    margin: 0 1.9em 0 0;
    max-width: 40.625rem;
  }
  .open_street_map .plan_route .saddr input {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 47.5em) {
  .open_street_map .plan_route .select_wrapper {
    margin: 0 1.9em 0 0;
  }
}
@media screen and (min-width: 47.5em) {
  .open_street_map .plan_route .select_wrapper .select {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 47.5em) {
  .open_street_map .plan_route .button_wrapper button {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 20rem) {
  .content_slider .slide_wrapper .slide {
    padding-right: calc(2.5rem + 2.1875
                            * (100vw - 20rem)
                            / (93.75 - 20));
  }
}
@media screen and (min-width: 93.75em) {
  .content_slider .slide_wrapper .slide {
    padding-right: 4.6875rem;
  }
}
@media screen and (min-width: 20rem) {
  .content_slider .slide_wrapper .slide {
    padding-left: calc(2.5rem + 2.1875
                            * (100vw - 20rem)
                            / (93.75 - 20));
  }
}
@media screen and (min-width: 93.75em) {
  .content_slider .slide_wrapper .slide {
    padding-left: 4.6875rem;
  }
}
@media screen and (max-width: 47.4375em) {
  .content_slider .slide_wrapper .slide .team_entry_wrapper .photo {
    padding-top: 1.9em;
    -webkit-box-flex: 0;
            flex: 0 0 auto;
  }
}
@media screen and (max-width: 34.9375em) {
  .content_slider .slide_wrapper .slide .team_entry_wrapper .photo {
    padding: 1.9em 0.475em 0.475em 0.475em;
    align-self: flex-end;
  }
}
@media screen and (max-width: 61.8125em) {
  .content_slider .slide_wrapper .slide .team_entry_wrapper .info {
    -webkit-box-flex: 0;
            flex: 0 0 48%;
    padding-left: 0.95em;
    margin: 0;
  }
}
@media screen and (max-width: 47.4375em) {
  .content_slider .slide_wrapper .slide .team_entry_wrapper .info {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
}
@media screen and (max-width: 34.9375em) {
  .content_slider .slide_wrapper .slide .team_entry_wrapper .info {
    -webkit-box-flex: 1;
            flex-grow: 1;
    padding: 0.95em 1.9em;
  }
}
@media screen and (max-width: 34.9375em) {
  .content_slider .slide_wrapper .slide .team_entry_wrapper .info.no_photo .buttons .text {
    display: none;
  }
}
@media screen and (max-width: 61.875em) {
  .karriere_slider .content_slider .slide_wrapper .slide:nth-child(odd) {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
}
@media screen and (min-width: 61.875em) {
  .karriere_slider .content_slider .slide_wrapper .slide:nth-child(odd) {
    padding-right: 1rem !important;
  }
}
@media screen and (min-width: 47.5em) {
  .teaser_benefits .teaser .title .icon svg {
    height: 5.625rem;
    width: 5.625rem;
  }
}
@media print {
  .hideme_print,
  .cms_live_management {
    display: none !important;
  }
  .showme_print {
    display: inherit !important;
    height: auto !important;
    max-height: none !important;
  }
  .company_logo_1 {
    display: none;
  }
  .company_logo_2 {
    display: block;
    margin: 0 auto;
    max-width: 18.75rem;
  }
  * {
    background: transparent !important;
    border-color: black !important;
    color: black !important;
    text-shadow: none !important;
  }
  *:before {
    color: black !important;
  }
  @page {
    margin: 2cm;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    page-break-after: avoid;
  }
  main a {
    text-decoration: underline;
  }
  main a.touch_link {
    margin-bottom: 1.9em;
    page-break-inside: avoid;
  }
  .table {
    overflow-y: inherit;
  }
  tr {
    page-break-inside: avoid;
  }
  dl {
    margin: 0;
  }
  dt {
    font-weight: 900;
    margin-bottom: 0.95em;
  }
  dd {
    margin: 0;
  }
  [role="tooltip"] {
    display: none;
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  .search dl br,
  .search dl em,
  .search dl img {
    display: none;
  }
  .news_entity .hide_creation_date {
    display: none;
  }
  body {
    display: block;
    overflow-x: visible;
  }
  .page {
    display: block;
  }
  main {
    padding-top: 1.9em;
  }
  .company_logo img {
    width: 50%;
  }
  .qr_code_print {
    border-top: 0.0625rem solid;
    margin: 1.9em 0 0;
    padding: 0.95em 0 0;
    page-break-inside: avoid;
  }
  .qr_code_print:after {
    clear: both;
  }
  .qr_code_print:after,
  .qr_code_print:before {
    content: "";
    display: table;
  }
  .qr_code_print img {
    float: left;
  }
  .qr_code_print p {
    margin-left: 10rem;
  }
}
